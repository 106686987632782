import { FeatureNameEnum, OcrFallbackStrategyVariant } from 'constants/featureDefinitions';
import { getFeatureVariant } from 'hooks/useFeatureFlags';
import { instrumentAction } from 'lib/observability';
import { SettingValue, SkipContentSettings } from 'modules/listening/features/settings/settings';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';

import type {
  ContentBundlerOptions,
  BundlerFactory as SDKBundlerFactory,
  ContentBundlerOptions as SDKContentBundlerOptions,
  EmbeddedReadingBundle as SDKEmbeddedReadingBundle,
  ReadingBundle as SDKReadingBundle,
  VoiceSpecOfAvailableVoice
} from '@speechifyinc/multiplatform-sdk';

import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';
import { ListenableContent } from './listenableContent';
import {
  ImportableListenableContent,
  isFileListenableContent,
  isItemListenableContent,
  shouldRelyOnSDKForDeterminingFileTitle
} from './listenableContent/utils';

export class SDKContentBundlerOptionsFacade extends SDKFacade {
  constructor(
    sdk: MultiplatformSDKInstance,
    private options: SDKContentBundlerOptions,
    private bundle: SDKReadingBundle | SDKEmbeddedReadingBundle
  ) {
    super(sdk);
  }

  updateIndividualSetting = <K extends keyof SkipContentSettings>(key: K, value: SettingValue<K>, purgeUtteranceCache = true) => {
    switch (key) {
      case 'braces':
        this.options.preSpeechTransformOptions.shouldSkipBraces = value;
        break;

      case 'brackets':
        this.options.preSpeechTransformOptions.shouldSkipBrackets = value;
        break;

      case 'citations':
        this.options.preSpeechTransformOptions.shouldSkipCitations = value;
        break;

      case 'enhancedSkipping':
        this.options.mlParsingMode = value ? this.sdk.sdkModule.MLParsingMode.ForceEnable : this.sdk.sdkModule.MLParsingMode.ForceDisable;
        break;

      case 'footers':
        this.options.shouldSkipFooters = value;
        break;

      case 'footnotes':
        this.options.shouldSkipFooters = value;
        break;

      case 'headers':
        this.options.shouldSkipHeaders = value;
        break;

      case 'parentheses':
        this.options.preSpeechTransformOptions.shouldSkipParentheses = value;
        break;

      case 'urls':
        this.options.preSpeechTransformOptions.shouldSkipUrls = value;
        break;

      default:
        console.warn(`Unhandled key ${key} in updateIndividualSetting`);
        break;
    }

    if (purgeUtteranceCache) this._purgeUtteranceCache();
  };

  updateSkipSettings = (skipSettings: SkipContentSettings) => {
    Object.entries(skipSettings).forEach(([key, value]) => this.updateIndividualSetting(key as keyof SkipContentSettings, value, false));
    this._purgeUtteranceCache();
  };

  private _purgeUtteranceCache = () => {
    // TODO(sdk-pain-point): Updating contentBundlerOptions don't reflect immediately and we manually need to reset the voice to reset the SDK's utterance cache,
    //   ideally SDK should expose a proper API to reset the utterance cache or make the update to the contentBundlerOptions/preSpeechTransforOptions to apply immediately.
    const voiceOfCurrentUtterance = this.bundle.playbackControls.state.voiceOfCurrentUtterance;
    if (voiceOfCurrentUtterance) {
      this.bundle.listeningBundle.audioController.setVoice(voiceOfCurrentUtterance);
    }
  };
}

type BundleRequirements = {
  initialVoice: VoiceSpecOfAvailableVoice;
  initialWPM: number;
  allVoices: VoiceSpecOfAvailableVoice[];
  shouldEnableOCR: boolean;
  listenableContent: ListenableContent;
};

type BundleFactoryRequirements = Omit<BundleRequirements, 'listenableContent'> & {
  contentBundlerOptions: ContentBundlerOptions;
};

type BundleOutput = {
  readingBundle: SDKReadingBundle;
  contentBundlerOptions: SDKContentBundlerOptionsFacade;
};

export class SDKBundleFacade extends SDKFacade {
  private static _singleton: SDKBundleFacade;

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKBundleFacade._singleton = this;
  }

  static override get singleton(): SDKBundleFacade {
    return SDKBundleFacade._singleton;
  }

  private getOcrStrategy = async (isOCREnabled: boolean) => {
    const sdk = this.sdk.sdkModule;
    if (!isOCREnabled) return sdk.OcrFallbackStrategy.ForceDisable;
    const variant = await getFeatureVariant(FeatureNameEnum.OCR_FALLBACK_STRATEGY);
    if (variant === OcrFallbackStrategyVariant.EXPERIMENTAL) {
      return sdk.OcrFallbackStrategy.ExperimentalStrategy;
    }
    return sdk.OcrFallbackStrategy.ConservativeLegacyStrategy;
  };

  private createContentBundlerOptions = async (isOCREnabled: boolean) => {
    const options = new this.sdk.sdkModule.ContentBundlerOptions();

    options.ocrFallbackStrategy = await this.getOcrStrategy(isOCREnabled);

    // Default all skip to false
    options.mlParsingMode = this.sdk.sdkModule.MLParsingMode.ForceDisable;
    options.shouldSkipHeaders = false;
    options.shouldSkipFooters = false;
    options.shouldSkipFooters = false;
    options.preSpeechTransformOptions.shouldSkipBraces = false;
    options.preSpeechTransformOptions.shouldSkipCitations = false;
    options.preSpeechTransformOptions.shouldSkipParentheses = false;
    options.preSpeechTransformOptions.shouldSkipBrackets = false;
    options.preSpeechTransformOptions.shouldSkipUrls = false;

    const isRichContentEnabled = await getFeatureVariant(FeatureNameEnum.RICH_CONTENT);
    if (isRichContentEnabled) {
      options.enableRichBlocksParsingForHtmlContent();
      options.enableRichBlocksParsingForEpubContent();
    }

    return options;
  };

  private handleImport = async (bundle: SDKReadingBundle, listenableContent: ImportableListenableContent) => {
    const WebBoundaryMap = this.sdk.WebBoundaryMap;
    const { ImportOptions } = this.sdk.sdkModule;

    const folderId = await listenableContent.folderId;
    let analyticsProperties: Record<string, unknown> = {};
    if (isFileListenableContent(listenableContent)) {
      analyticsProperties = listenableContent.analyticsProperties || {};
    }
    const callback = await listenableContent.onImportCompleteCallback;

    bundle.contentImporter.startImport(
      new ImportOptions(
        // Pass empty string for title if content is URL so that SDK can fetch the title from the URL
        shouldRelyOnSDKForDeterminingFileTitle(listenableContent) ? '' : listenableContent.title,
        folderId,
        new WebBoundaryMap({
          prop: 'value',
          ...analyticsProperties
        })
      ),
      callback
    );
  };

  private createBundleFactory = async ({ initialVoice, initialWPM, allVoices, contentBundlerOptions }: BundleFactoryRequirements) => {
    const { client, sdkModule: sdk } = this.sdk;
    const isSlidingWindowEnabled = await getFeatureVariant(FeatureNameEnum.SDK_SLIDING_WINDOW);

    const listeningBundlerOptions = new sdk.ListeningBundlerOptions();
    listeningBundlerOptions.textToSpeechIncludePrecedingContextForAudioSynthesisOverride = isSlidingWindowEnabled;

    profilingStoreActions.startMeasurement(MeasurementKey.sdkBundleFactoryCreation);
    const factory = client.createBundlerFactory(
      new sdk.BundlerFactoryConfig(
        new sdk.ClassicReadingBundlerConfig(new sdk.ClassicReadingBundlerOptions()),
        new sdk.BookReadingBundlerConfig(new sdk.BookReadingBundlerOptions(true)),
        new sdk.EmbeddedReadingBundlerConfig(new sdk.EmbeddedReadingBundlerOptions()),
        new sdk.ListeningBundlerConfig(
          new sdk.AudioConfig(sdk.AudioMediaFormat.MP3),
          initialWPM,
          allVoices,
          new sdk.VoicePreferences(
            new sdk.VoicePreferenceWithStaticValue(initialVoice), //
            new sdk.VoicePreferenceWithStaticValue(initialVoice)
          ),
          listeningBundlerOptions
        ),
        new sdk.ContentBundlerConfig(contentBundlerOptions)
      ),
      plugins => plugins
    );
    profilingStoreActions.endMeasurement(MeasurementKey.sdkBundleFactoryCreation);
    return factory;
  };

  private createReadingBundle = async (listenableContent: ListenableContent, factory: SDKBundlerFactory): Promise<SDKReadingBundle> => {
    const sdk = this.sdk.sdkModule;
    const promisify = this.sdk.promisify;
    const universalBundle = factory.universalSourcesReadingBundler;

    if (isItemListenableContent(listenableContent)) {
      const createBundleForResource = promisify(universalBundle.createBundleForResource.bind(universalBundle));
      const bundle = await createBundleForResource(
        sdk.SpeechifyURI.Companion.fromExistingId(sdk.SpeechifyEntityType.LIBRARY_ITEM, listenableContent.itemId),
        null
      );
      return bundle;
    }

    const createBundle = async () => {
      const importStartChoice = sdk.ImportStartChoice.DoNotStart;
      if (isFileListenableContent(listenableContent)) {
        const createBundleForBlob = promisify(universalBundle.createBundleForBlob.bind(universalBundle));
        return createBundleForBlob(listenableContent.file, importStartChoice, null);
      }

      const createBundleForURL = promisify(universalBundle.createBundleForURL.bind(universalBundle));
      return createBundleForURL(listenableContent.url, importStartChoice, null);
    };

    // note: Import is handled separately in background by the `handleImport` function above which will wait for few deps to resolve.
    const bundle = await createBundle();

    // Run the import in the next tick to avoid blocking the main thread for Instant Listening
    setTimeout(() => {
      this.handleImport(bundle, listenableContent);
    }, 1);

    return bundle;
  };

  public createBundles = async ({ listenableContent, ...factoryRequirements }: BundleRequirements): Promise<BundleOutput> => {
    const contentBundlerOptions = await instrumentAction(
      () => this.createContentBundlerOptions(factoryRequirements.shouldEnableOCR),
      'createContentBundlerOptions'
    );
    const factory = await this.createBundleFactory({
      ...factoryRequirements,
      contentBundlerOptions
    });

    const readingBundle = await instrumentAction(() => this.createReadingBundle(listenableContent, factory), 'createReadingBundle');

    return {
      readingBundle: readingBundle,
      contentBundlerOptions: new SDKContentBundlerOptionsFacade(this.sdk, contentBundlerOptions, readingBundle)
    };
  };

  // const { factory, initialVoice, contentBundlerOptions } = await createBundlerFactory();
  // const embeddedBundler = factory.embeddedReadingBundler;
  // const bundle = await promisify(embeddedBundler.createBundleForHtmlElement.bind(embeddedBundler))(el, undefined);

  // return new EmbeddedReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, {});

  public createEmbeddedBundles = async ({ element, ...factoryRequirements }: { element: HTMLElement } & Omit<BundleRequirements, 'listenableContent'>) => {
    const contentBundlerOptions = await this.createContentBundlerOptions(factoryRequirements.shouldEnableOCR);
    const factory = await this.createBundleFactory({
      ...factoryRequirements,
      contentBundlerOptions
    });
    const promisify = this.sdk.promisify;
    const embeddedBundler = factory.createEmbeddedReadingBundler();

    const bundle = await promisify(embeddedBundler.createBundleForHtmlElement.bind(embeddedBundler))(element, undefined);
    return bundle;
  };
}
