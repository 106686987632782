const CORS_ALLOWED_HOSTS = new Set([
  'localhost',
  '0.0.0.0',
  '127.0.0.1',
  'us-central1-speechifydev.cloudfunctions.net',
  'speechifydev.appspot.com',
  'speechifymobile.appspot.com',
  'us-central1-speechifymobile.cloudfunctions.net',
  'payment-server-dot-speechifymobile.uc.r.appspot.com',
  'payment-server-dot-speechifydev.uc.r.appspot.com',
  'speechifydev.uc.r.appspot.com',
  'firebasedynamiclinks.googleapis.com',
  'firebasestorage.googleapis.com',
  'audio.api.speechify.com',
  'audio.api.speechify.dev',
  'dev.audio.api.speechify.com',
  'dev.audio.api.speechify.dev',
  'speechify.ngrok.io',
  'payment.api.speechify.dev',
  'payment.api.speechify.com',
  'dev.payment.api.speechify.dev',
  'storage.googleapis.com',
  'ml-parsing.api.speechify.dev',
  'ml-parsing.api.speechify.com',
  'voices.api.speechify.com',
  'dev.voices.api.speechify.dev',
  'dev.voices.api.speechify.com',
  'sdk-cors-proxy-kcknmuja3a-uc.a.run.app',
  'sdk-cors-proxy-cp25m4fspa-uc.a.run.app',
  'integration.api.speechify.com',
  'integration.api.speechify.dev',

  // TODO: Remove these two aichat-service URLs once we have the new LLM service deployed
  'aichat-service-cp25m4fspa-uc.a.run.app',
  'aichat-service-kcknmuja3a-uc.a.run.app',

  'books.api.speechify.dev',
  'books.api.speechify.com',
  'llm.api.speechify.com',
  'llm.api.speechify.dev'
]);

export function isCorsAllowed(url: string): boolean {
  if (!url) return true;
  if (url.startsWith('blob:')) return true;

  return CORS_ALLOWED_HOSTS.has(new URL(url).hostname);
}

export function wrapWithCorsProxy(targetUrl: string, requestParameters: { [k: string]: string } = {}): string {
  const targetUrlWithParams = new URL(targetUrl);
  Object.keys(requestParameters).forEach(k => targetUrlWithParams.searchParams.set(k, requestParameters[k]));

  const proxy = `${process.env.NEXT_PUBLIC_CORS_PROXY_URL}`;
  const url = new URL(proxy);

  url.searchParams.set('url', targetUrlWithParams.toString());

  return url.toString();
}
