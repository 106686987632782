import { SDKVoiceFacade } from 'modules/sdk/lib';

import { useVoiceStore } from '../voicesStore';

export const refreshPersonalVoices = async () => {
  try {
    const updatedPersonalVoices = SDKVoiceFacade.singleton.getPersonalVoiceInfos();
    useVoiceStore.setState({
      personalVoices: updatedPersonalVoices
    });
  } catch (e) {
    console.error('Error refreshing personal voices', e);
  }
};
