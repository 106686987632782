import { createNonPersistentStore } from 'lib/zustand';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { SummaryDialogState } from 'modules/listening/components/ChatAskAiSDKbased';
import { getSDK, PlaybackInfo } from 'modules/sdk/lib';
import { ChatMessage } from 'modules/sdk/lib/facade/askAi';

type AskAiState = {
  messages: ChatMessage[];
  autoPlay: boolean;
  isLoading: boolean;
  currentlyPlayingMessageId: string | null;
  userInteracted: boolean;
  playbackInfo: PlaybackInfo | null;
  currentDocId: string | null;
};

export const useAskAiStore = createNonPersistentStore<AskAiState>(() => {
  return {
    currentDocId: null,
    messages: [],
    isLoading: false,
    autoPlay: true,
    currentlyPlayingMessageId: null,
    userInteracted: false,
    playbackInfo: null
  };
});

const fetchAskAiChatsForCurrentDocument = async (itemId: string) => {
  const { askAi } = await getSDK();

  const { messages: existingMessages, currentDocId } = useAskAiStore.getState();

  if (currentDocId === itemId) return;

  try {
    if (existingMessages.length === 0) {
      useAskAiStore.setState({ isLoading: true });
    }
    await askAi.initializeChatForDocument(itemId);

    const messages = await askAi.getTransformedMessages();

    askAi.setMessagesChangeListener(messages => {
      useAskAiStore.setState({ messages });
    });

    useAskAiStore.setState({ messages: messages.filter(Boolean) as ChatMessage[], currentDocId: itemId });
  } finally {
    useAskAiStore.setState({ isLoading: false });
  }
};

const sendPrompt = async (prompt: string) => {
  const { askAi } = await getSDK();
  setUserInteracted(true);
  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, { action: 'chat', query: prompt });
  await askAi.sendMessage(prompt);
};

const summarizeCurrentPage = async (currentPageIndex: number) => {
  const { askAi } = await getSDK();
  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    options: { mode: 'paragraph', length: 'short', pageIndexes: [currentPageIndex + 1], allPages: false }
  });
  await askAi.summarizePages([currentPageIndex + 1], 'short', 'paragraph');
  setUserInteracted(true);
};

const summarizeDocument = async (length: SummaryDialogState['length'], mode: SummaryDialogState['mode']) => {
  const { askAi } = await getSDK();
  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    options: { mode, length, pageIndexes: [], allPages: true }
  });
  await askAi.summarizeDocument(length, mode);
  setUserInteracted(true);
};

const summarizePages = async (pageIndexes: number[], length: SummaryDialogState['length'], mode: SummaryDialogState['mode']) => {
  const { askAi } = await getSDK();
  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    options: { mode, length, pageIndexes, allPages: false }
  });
  await askAi.summarizePages(
    pageIndexes.map(p => p + 1), // sdk expects pageNumbers from 1 base instead of 0
    length,
    mode
  );
  setUserInteracted(true);
};

const regenerate = async () => {
  const { askAi } = await getSDK();
  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'summary_regenerate'
  });
  await askAi.regenerateMessage();
  setUserInteracted(true);
};

const deleteChat = async () => {
  const { askAi } = await getSDK();
  await askAi.deleteChat();
  useAskAiStore.reset();
  setUserInteracted(true);
};

const setAutoPlay = (autoPlay: boolean) => {
  logAnalyticsEvent(AnalyticsEventKey.documentAskAiAutoplayResponses, {
    state: autoPlay ? 'on' : 'off'
  });
  useAskAiStore.setState({ autoPlay: autoPlay });
};

const setPlaybackInfo = (playbackInfo: PlaybackInfo | null, messageId: string | null) => {
  useAskAiStore.setState({
    playbackInfo,
    currentlyPlayingMessageId: messageId
  });
};

const toggleAutoPlay = () => {
  useAskAiStore.setState(s => {
    const toggledValue = !s.autoPlay;
    logAnalyticsEvent(AnalyticsEventKey.documentAskAiAutoplayResponses, {
      state: toggledValue ? 'on' : 'off'
    });
    return { autoPlay: toggledValue };
  });
};

const setUserInteracted = (userInteracted: boolean) => {
  useAskAiStore.setState({ userInteracted });
};

const cleanUpAskAi = async () => {
  const { askAi } = await getSDK();
  askAi.destroy();
  useAskAiStore.reset();
};

export const askAiStoreActions = {
  fetchChats: fetchAskAiChatsForCurrentDocument,
  sendPrompt,
  summarizeDocument,
  deleteChat,
  cleanUpAskAi,
  regenerate,
  summarizeCurrentPage,
  summarizePages,
  toggleAutoPlay,
  setAutoPlay,
  setUserInteracted,
  setPlaybackInfo
};
