import { logAnalyticsEventOnVoiceChange } from 'modules/analytics/impl/playbackAnalytics';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import type { PlaybackInfo, VoiceInfo } from 'modules/sdk/lib';
import { speedStoreActions } from 'modules/speed/store/speedStore';
import { showUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';
import backwardsCompatibility from 'modules/utils/backwards-compatibility';

import { getVoiceState } from '../selectors/getVoiceState';
import { useVoiceStore, VoiceStoreState } from '../voicesStore';
import { playPreview } from './playPreview';

const MAX_HISTORY_SIZE = 13;
const buildHistory = (newVoice: VoiceInfo, state: VoiceStoreState) => {
  const { selectionHistory } = state;
  // filter out the new voice from the history first
  let newHistory = selectionHistory.filter(v => v.id !== newVoice.id);

  // add the new voice to the front of the history
  newHistory = [newVoice, ...newHistory];

  // limit the history to a maximum size
  newHistory = newHistory.slice(0, MAX_HISTORY_SIZE);

  return newHistory;
};

export const setVoice = (
  voice: VoiceInfo,
  {
    currentPlaybackInfo = null,
    shouldPlayPreview = true,
    isChangeFromExtension = false
  }: {
    currentPlaybackInfo?: PlaybackInfo | null;
    shouldPlayPreview?: boolean;
    isChangeFromExtension?: boolean;
  } = {
    currentPlaybackInfo: null,
    shouldPlayPreview: true,
    isChangeFromExtension: false
  }
) => {
  if (getVoiceState({ voiceInfo: voice }).state === 'locked') {
    showUpsellModal(UpsellModalType.ContextualPremiumVoices);
    return;
  }

  useVoiceStore.setState(state => {
    return {
      lastPremiumVoice: voice.isPremium ? voice : state.lastPremiumVoice,
      lastPlayingVoice: voice,
      selectionHistory: buildHistory(voice, state)
    };
  });

  try {
    const playbackInfo = currentPlaybackInfo ?? usePlaybackStore.getState().currentPlaybackInfo;
    playbackInfo?.controls.setVoice(voice);

    // adjust speed if non-premium voice is selected
    if (!voice.isPremium) {
      speedStoreActions.switchToNonPremiumSpeed();
    }

    // play preview if the playback is paused
    if (shouldPlayPreview) playPreview(voice, playbackInfo);

    // legacy support
    if (!isChangeFromExtension) backwardsCompatibility.setVoice(voice);

    logAnalyticsEventOnVoiceChange(voice);
  } catch (e) {
    // TODO(overhaul): Proper error handling when failed to switch voice
    console.error('Error setting voice', e);
  }
};
