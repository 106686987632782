// ESLint: 'GamificationVariantEnum' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FeatureNameEnum, GamificationVariantEnum } from 'constants/featureDefinitions';
import { getFeatureVariant } from 'hooks/useFeatureFlags';
import { IUser } from 'interfaces';
import { isUserNew } from 'store/auth/helpers';
import { getCustomAccountSetting, setCustomAccountSetting } from 'utils/baseAccountSettings';

export async function getGamificationFeatureFlag(user: IUser) {
  const variant = await getFeatureVariant(FeatureNameEnum.GAMIFICATION);
  const isNew = isUserNew(user);
  if (variant === 'enabled' && isNew) {
    setCustomAccountSetting('isGamificationNewUser', 'true');
  }

  return variant;
}

// We should only show gamification statistics for user that is assigned to the 'enabled' variant and was ever a new user during the time of assignment.
// ESLint: 'user' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function shouldShowGamificationStatistics(user: IUser): Promise<boolean> {
  // ESLint: 'e' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setting = await getCustomAccountSetting('isGamificationNewUser').catch(e => {});
  return setting === 'true';
}
