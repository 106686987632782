import { binaryContentToDataUrl } from 'utils/binaryContent';

import {
  BinaryContentReadableRandomly,
  BinaryContentWithMimeTypeFromNativeReadableInChunks,
  binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob,
  ImageConverter,
  ImageFormat
} from '@speechifyinc/multiplatform-sdk';

import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback } from './lib/typeAliases';

export class WebImageConverter extends ImageConverter {
  private async convertToWebpAsync(
    inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    targetQualityPercent: number
  ): Promise<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> {
    const imageDataUrl = await binaryContentToDataUrl(inputImage);
    const image = await loadImage(imageDataUrl);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;

    context!.drawImage(image, 0, 0, canvas.width, canvas.height);

    return binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob(await canvasToBlob(canvas, 'image/webp', targetQualityPercent));
  }

  protected override convertToWithQualityPercent(
    inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    imageFormat: ImageFormat,
    targetQualityPercent: number,
    callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>
  ): void {
    if (imageFormat === ImageFormat.WEBP) {
      callbackFromAsync(async () => await this.convertToWebpAsync(inputImage, targetQualityPercent), callback);
    } else {
      throw Error(`Converting the Image to ${imageFormat} is not implemented yet!`);
    }
  }

  override cappedWidthJpeg = (
    inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    targetMaxWidth: number,
    targetQualityPercent: number,
    callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>
  ) => callbackFromAsync(async () => await this.cappedWidthJpegAsync(inputImage, targetMaxWidth, targetQualityPercent), callback);

  async cappedWidthJpegAsync(
    inputImage: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    targetMaxWidth: number,
    targetQualityPercent: number
  ): Promise<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> {
    const imageDataUrl = await binaryContentToDataUrl(inputImage);
    const image = await loadImage(imageDataUrl);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const scaleFactor = Math.min(targetMaxWidth / image.width, 1);

    canvas.width = image.width * scaleFactor;
    canvas.height = image.height * scaleFactor;

    context!.drawImage(image, 0, 0, canvas.width, canvas.height);
    return binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob(await canvasToBlob(canvas, 'image/jpeg', targetQualityPercent));
  }
}

async function canvasToBlob(canvas: HTMLCanvasElement, targetMimeType: string, targetQualityPercent?: number): Promise<Blob> {
  return await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject('Failed to convert image to blob');
        }
      },
      targetMimeType,
      targetQualityPercent ? targetQualityPercent / 100 : undefined
    );
  });
}

async function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = e => reject(e);
    image.src = url;
  });
}
