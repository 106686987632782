import { SearchResult } from 'interfaces/search';
import { RootState } from 'store';

import { createSelector, createSlice } from '@reduxjs/toolkit';

const name = 'reader';

const PAGE_CHANGE_SOURCE = {
  THUMBNAILS: 'thumbnails',
  READER: 'reader',
  SEARCH: 'search'
} as const;

const READER_TYPE = {
  BOOK_READER: 'BookReader',
  CLASSIC_READER: 'ClassicReader'
} as const;

type ObjectValues<t> = t[keyof t];
type PageChangeSource = ObjectValues<typeof PAGE_CHANGE_SOURCE>;
type ReaderType = ObjectValues<typeof READER_TYPE>;

type ReaderState = {
  pageIndex: number;
  pageIndexSetBy: PageChangeSource;
  readerType: ReaderType | null;
  searchResults: SearchResult[];
  currentSearchResultIndex: number;
  searchTerm: string;
  thumbnails: Record<number, string>;
};

const initialState: ReaderState = {
  pageIndex: 0,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PageChangeS... Remove this comment to see the full error message
  pageIndexSetBy: null,
  readerType: null,
  searchResults: [],
  currentSearchResultIndex: 0,
  searchTerm: '',
  thumbnails: {}
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setPageIndex: (state, action: { payload: { pageIndex: number; pageIndexSetBy: PageChangeSource } }) => {
      state.pageIndex = action.payload.pageIndex;
      state.pageIndexSetBy = action.payload.pageIndexSetBy;
    },
    setReaderType: (state, action: { payload: { readerType: ReaderType | null } }) => {
      state.readerType = action.payload.readerType;
    },
    setSearchTerm: (state, action: { payload: { searchTerm: string } }) => {
      state.searchTerm = action.payload.searchTerm;
    },
    setSearchResults: (state, action: { payload: { searchResults: SearchResult[] | null } }) => {
      // @ts-expect-error TS(2322): Type 'SearchResult[] | null' is not assignable to ... Remove this comment to see the full error message
      state.searchResults = action.payload.searchResults;
    },
    setCurrentSearchResultIndex: (state, action: { payload: { currentSearchResultIndex: number } }) => {
      state.currentSearchResultIndex = action.payload.currentSearchResultIndex;
    },
    clearReaderState: state => {
      state.pageIndex = 0;
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PageChangeS... Remove this comment to see the full error message
      state.pageIndexSetBy = null;
      state.readerType = null;
      state.currentSearchResultIndex = 0;
      state.searchResults = [];
      state.thumbnails = {};
    },
    cacheThumbnail: (state, action: { payload: { url: string; index: number } }) => {
      state.thumbnails[action.payload.index] = action.payload.url;
    }
  }
});

export const getPageIndex = () =>
  createSelector(
    (state: RootState) => state.reader,
    (reader: ReaderState) => {
      return { pageIndex: reader.pageIndex, pageIndexSetBy: reader.pageIndexSetBy };
    }
  );

export const getThumbnail = (page: number) =>
  createSelector(
    (state: RootState) => state.reader,
    (reader: ReaderState) => reader.thumbnails[page]
  );

export const getSearchResults = () =>
  createSelector(
    (state: RootState) => state.reader,
    (reader: ReaderState) => reader.searchResults
  );

export const getCurrentSearchResultIndex = () =>
  createSelector(
    (state: RootState) => state.reader,
    (reader: ReaderState) => reader.currentSearchResultIndex
  );

export const getReaderType = () =>
  createSelector(
    (state: RootState) => state.reader,
    (reader: ReaderState) => reader.readerType
  );

export const getSearchTerm = () =>
  createSelector(
    (state: RootState) => state.reader,
    (reader: ReaderState) => reader.searchTerm
  );

const actions = generatedActions;

const selectors = {
  getPageIndex,
  getSearchTerm,
  getSearchResults,
  getCurrentSearchResultIndex,
  getReaderType,
  getThumbnail
};

export { actions, selectors };

export default reducer;
