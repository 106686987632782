import { logError } from 'lib/observability';

import {
  Result,
  SqlDriverFactoryUsingWebWorkerForInMemoryOnly,
  SqlDriverFactoryUsingWebWorkerForInMemoryOnlyDependenciesBase
} from '@speechifyinc/multiplatform-sdk';

class FactoryDependency extends SqlDriverFactoryUsingWebWorkerForInMemoryOnlyDependenciesBase {
  private _worker: Worker | undefined;

  get worker(): Worker {
    if (this._worker) return this._worker;

    this._worker = new Worker(new URL('./sqlite.worker.ts', import.meta.url), {
      type: 'module',
      name: 'sqlite.worker.js'
    });

    this._worker.addEventListener('message', event => {
      if (event.data.error) {
        logError(new Error(`sqlite ran into error: ${event.data.error}`));
      }
    });

    return this._worker;
  }

  ensureDbDoesNotExist(callback: (p0: Result<void>) => void): void {
    // Since we are using InMemory DB, SDK ensureDbDoesNotExist() is already a no-op,
    // so we don't need to do anything here, but we should still call the callback just in case.
    return callback(new Result.Success<void>(undefined));
  }
}

export class InMemorySqlDriverFactory extends SqlDriverFactoryUsingWebWorkerForInMemoryOnly {
  constructor() {
    super(new FactoryDependency());
  }
}
