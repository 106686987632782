import { WebAppListeningExperienceOverhaulVariant } from 'constants/featureDefinitions';
import type { ListenableContent } from 'modules/sdk/lib';

import { ReadingInfo } from '../components/experience/readers/newsdk/ReadingInfo';

export interface LoadingInstantListening {
  flowStartTimestamp: number;
  isLoading: true;
  listeningOverhaulVariant: WebAppListeningExperienceOverhaulVariant;
  details?: never;
}

export interface LoadedInstantListeningV1 {
  flowStartTimestamp: number;
  isLoading: false;
  listeningOverhaulVariant: WebAppListeningExperienceOverhaulVariant.CONTROL;
  details: {
    readingInfo: ReadingInfo;
  };
}

export interface LoadedInstantListeningV2 {
  flowStartTimestamp: number;
  isLoading: false;
  listeningOverhaulVariant: WebAppListeningExperienceOverhaulVariant.ENABLED;
  details: {
    listenableContent: ListenableContent;
  };
}

export type InstantListening = LoadingInstantListening | LoadedInstantListeningV1 | LoadedInstantListeningV2;

export type IMainPageState = {
  activeDropTargetId: string | undefined;
  activeDragTargetId: string | undefined;
  instantListening: InstantListening | null;
  isDragging: boolean;
  mobileAction: string;
  newFolderParentId: string | undefined;
  newlyImportedItemId: string | null;
};

export const isInstantListeningV1 = (state: InstantListening): state is LoadingInstantListening | LoadedInstantListeningV1 =>
  state.listeningOverhaulVariant === WebAppListeningExperienceOverhaulVariant.CONTROL;
export const isInstantListeningV1Loaded = (state: InstantListening): state is LoadedInstantListeningV1 =>
  state.isLoading === false && 'readingInfo' in state.details;
export const isInstantListeningV2Loaded = (state: InstantListening): state is LoadedInstantListeningV2 =>
  state.isLoading === false && 'listenableContent' in state.details;
