import { auth } from 'lib/speechify';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateDetails = createAsyncThunk('auth/updateTeamDetails', async (team: { displayName?: string; iconUrl?: string }) => {
  const { displayName, iconUrl } = team;
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  const idToken = await auth.currentUser.getIdToken();

  const response: string = await fetch(`/api/team`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ displayName, iconUrl })
  })
    .then(response => response.json())
    .then(result => result.message);

  return response;
});
