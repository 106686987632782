import axios, { type AxiosResponse } from 'axios';
import { ChatBody, ChatResponse } from 'pages/api/ask-ai/chat';

import type { AskRequestBody, AskResponse } from '../pages/api/ask';
import type { SummarizeBody, SummarizeResponse } from '../pages/api/ask-ai/summarize';
import { auth } from './speechify';

export const askAi = (params: AskRequestBody) => {
  return axios.post<AskResponse, AxiosResponse<AskResponse>, AskRequestBody>('/api/ask', params).then(response => {
    return response.data;
  });
};

export const summarizeAi = async (params: SummarizeBody) => {
  const idToken = await auth.currentUser?.getIdToken();

  return axios
    .post<SummarizeResponse, AxiosResponse<SummarizeResponse>, SummarizeBody>('/api/ask-ai/summarize', params, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
    .then(response => {
      return response.data;
    });
};

export const chatAi = async (params: ChatBody) => {
  const idToken = await auth.currentUser?.getIdToken();

  return axios
    .post<SummarizeResponse, AxiosResponse<ChatResponse>, ChatBody>('/api/ask-ai/chat', params, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
    .then(response => {
      return response.data;
    });
};
