import { TranslationQuery } from 'next-translate';
import useOrignialNextTranslation from 'next-translate/useTranslation';
import { Paths } from 'utils/types';

import en from '../../locales/en/common.json';
import { useMemo } from 'react';

export type TranslationKeys = {
  common: Paths<typeof en>; //gets the keys from the json file and creates a type for them
};

export const useTranslation = <T extends keyof TranslationKeys>(ns: T) => {
  const { t, lang } = useOrignialNextTranslation(ns);
  const returnValue = useMemo(
    () => ({
      t: (
        s: TranslationKeys[T],
        q?: TranslationQuery,
        o?: {
          returnObjects?: boolean;
          fallback?: string | string[];
          default?: string;
        }
      ) => t(s, q, o),
      lang
    }),
    [t, lang]
  );

  return returnValue;
};
