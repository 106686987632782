import { allLogLevels, BaseInstrumentation, LogLevel, VERSION, PushErrorOptions } from '@grafana/faro-core';

export class ConsoleInstrumentation extends BaseInstrumentation {
  readonly name = 'speechify-custom-console-instrumentation';
  readonly version = VERSION;

  initialize() {
    this.logDebug('Initializing\n');

    allLogLevels
      .filter(level => ![LogLevel.DEBUG, LogLevel.TRACE, LogLevel.LOG].includes(level))
      .forEach(level => {
        console[level] = (...args) => {
          try {
            // log errors as exceptions for simpler analysis
            if (level === 'error') {
              const options: PushErrorOptions = { type: 'console' };
              let error = args.find(arg => arg instanceof Error);

              // convert strings into Error object
              if (!error) {
                error = new Error(args.filter(arg => typeof arg === 'string').join(' ') || 'Unknown console error');
                options.stackFrames = [];
              }

              this.api.pushError(error, options);
            } else {
              this.api.pushLog(args, { level });
            }
          } catch (err) {
            this.logError(err);
          } finally {
            this.unpatchedConsole[level](...args);
          }
        };
      });
  }
}
