import { UserUsageData } from 'interfaces/usage';

import { createSlice } from '@reduxjs/toolkit';

import { fetchUserUsageData } from './actions';

const name = 'usage';
const initialState: { userData: UserUsageData; userHasReadAtLeastOneItem: boolean } = {
  userData: {
    android: null,
    ios: null,
    webApp: null,
    chromeExtension: null,
    safariExtension: null,
    macApp: null
  },
  userHasReadAtLeastOneItem: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setUserHasReadAtLeastOneItem: (state, action) => {
      state.userHasReadAtLeastOneItem = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserUsageData.fulfilled, (state, action) => {
      // @ts-expect-error TS(2322): Type 'UserUsageData | undefined' is not assignable... Remove this comment to see the full error message
      state.userData = action.payload;
    });
  }
});

const actions = { ...generatedActions, fetchUserUsageData };
const selectors = {};

export { actions, selectors };

export default reducer;
