import { WordsListenedData, WordsListenedEntity } from 'interfaces/settings';
import { sortBy } from 'lodash';

const DEFAULT_VALUE = {
  daily: [],
  monthly: [],
  weekly: [],
  totalWords: 0
};

function sumUsageByPeriod(
  period: 'daily' | 'monthly' | 'weekly',
  android: WordsListenedData = DEFAULT_VALUE,
  iOS: WordsListenedData = DEFAULT_VALUE,
  web: WordsListenedData = DEFAULT_VALUE,
  ext: WordsListenedData = DEFAULT_VALUE
): WordsListenedEntity[] {
  return sortBy(
    Object.values(
      [...android[period], ...iOS[period], ...web[period], ...ext[period]].reduce((acc, { date, words }) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[date] = { date, words: (acc[date] ? acc[date].words : 0) + words };
        return acc;
      }, {})
    ),
    item => new Date(item.date).getTime()
  );
}
export function combinePlatformUsageStats(
  android: WordsListenedData = DEFAULT_VALUE,
  iOS: WordsListenedData = DEFAULT_VALUE,
  web: WordsListenedData = DEFAULT_VALUE,
  ext: WordsListenedData = DEFAULT_VALUE
): WordsListenedData {
  const daily: WordsListenedEntity[] = sumUsageByPeriod('daily');

  const weekly: WordsListenedEntity[] = sumUsageByPeriod('weekly');

  const monthly: WordsListenedEntity[] = sumUsageByPeriod('monthly');

  const totalWords = android.totalWords + iOS.totalWords + web.totalWords + ext.totalWords;

  return { daily, weekly, monthly, totalWords };
}
