import { instrumentAction } from 'lib/observability';
import { PSPDFKIT_KEY } from 'lib/pdf/constants';
import { debounce, isEqual, isNumber, reduce } from 'lodash';

let instance = null;
let instanceScrollElement = null;
let instanceZoomElement = null;
let instanceViewport = null;
let instanceHighlightContainer = null;

let pageIndexChangeHandler = () => {};
let viewStateHandler = () => {};
let zoomHandler = () => {};

let unsubscribeFromStore;

export const INITIAL_ZOOM = 1.6;

export async function importPSPDFKIT() {
  return import(/* webpackChunkName: 'lib-pspdfkit  ' */ 'pspdfkit').then(module => module.default);
}

export async function preloadPSPDFKIT(defaultConfiguration) {
  const PSPDFKit = await importPSPDFKIT();
  await PSPDFKit.preloadWorker({
    ...defaultConfiguration,
    initialViewState: new PSPDFKit.ViewState({ currentPageIndex: 0, showToolbar: false, zoom: INITIAL_ZOOM }),
    licenseKey: PSPDFKIT_KEY,
    toolbarItems: PSPDFKit.defaultToolbarItems,
    theme: PSPDFKit.Theme.AUTO
  });
}

export async function load(defaultConfiguration, zoomCallback, focusCallback, pageIndexChangeCallback, customCss, isDarkMode) {
  const PSPDFKit = await instrumentAction(importPSPDFKIT, 'importPSPDFKIT');
  const newInstance = await instrumentAction(
    () =>
      PSPDFKit.load({
        ...defaultConfiguration,
        initialViewState: new PSPDFKit.ViewState({ currentPageIndex: 0, showToolbar: false, zoom: INITIAL_ZOOM }),
        licenseKey: PSPDFKIT_KEY,
        toolbarItems: PSPDFKit.defaultToolbarItems,
        theme: PSPDFKit.Theme.AUTO
      }),
    'PSPDFKit.load'
  );

  instance = newInstance;

  try {
    instanceScrollElement = instance.contentDocument.querySelector('.PSPDFKit-Scroll');
    instanceZoomElement = instance.contentDocument.querySelector('.PSPDFKit-Zoom');
    instanceViewport = instance.contentDocument.querySelector('.PSPDFKit-Viewport');
    instanceHighlightContainer = instance.contentDocument.querySelector('.PSPDFKit-Scroll > div');

    instanceZoomElement.style.position = 'relative';

    instanceHighlightContainer = document.createElement('div');
    instanceHighlightContainer.style.position = 'absolute';
    instanceHighlightContainer.style.top = '0';
    instanceHighlightContainer.style.left = '0';
    instanceHighlightContainer.style.width = '100%';
    instanceHighlightContainer.style.height = '100%';

    if (customCss) {
      const style = document.createElement('style');

      style.appendChild(document.createTextNode(customCss));

      instance.contentDocument.appendChild(style);
    }

    instanceZoomElement.appendChild(instanceHighlightContainer);

    updateDarkMode(isDarkMode);

    // toolbar items
    const items = instance.toolbarItems;
    const exlcudedItems = [
      'arrow',
      'cloudy-polygon',
      'debug',
      'document-crop',
      'document-editor',
      'ellipse',
      'highlighter',
      'image',
      'ink',
      'ink-eraser',
      'line',
      'link',
      'note',
      'polygon',
      'polyline',
      'rectangle',
      'signature',
      'stamp',
      'text-highlighter',
      'text',
      'zoom-mode'
    ];

    instance.setToolbarItems(
      items.filter(item => {
        return !exlcudedItems.includes(item.type);
      })
    );
  } catch (e) {
    console.error('Error setting up PSPDFKit', e);
  }

  pageIndexChangeHandler = pageIndex => {
    setTimeout(() => {
      pageIndexChangeCallback(pageIndex, instanceScrollElement.scrollTop);
    }, 100);
  };

  viewStateHandler = (viewState, previousViewState) => {
    const current = viewState.toJS();
    const previous = previousViewState.toJS();

    // what changed?
    const changed = reduce(
      current,
      function (result, value, key) {
        return isEqual(value, previous[key]) ? result : result.concat(key);
      },
      []
    );

    if (changed.indexOf('currentPageIndex') !== -1) {
      pageIndexChangeHandler(current.currentPageIndex);
    }

    if (current.interactionMode || current.sidebarMode) {
      focusCallback(true, current);
    } else {
      focusCallback(false, current);
    }
  };

  zoomHandler = debounce(zoom => {
    if (!isNumber(zoom) || !instance) return;

    const firstPage = instance.pageInfoForIndex(0);
    const firstPageMatrix = getTransformValues(instanceZoomElement);

    const pageHeight = firstPage?.height || 0;
    const pageTop = firstPageMatrix.y || 0;
    const pageWidth = firstPage?.width || 0;

    zoomCallback(zoom, pageWidth, pageHeight, pageTop);
  }, 250);

  instance.addEventListener('viewState.change', viewStateHandler);
  instance.addEventListener('viewState.zoom.change', zoomHandler);

  zoomHandler(instance.currentZoomLevel);

  return {
    instance,
    module: PSPDFKit
  };
}

export function getInstance() {
  return instance;
}

export function updateDarkMode(isDarkMode) {
  if (instanceViewport) {
    if (isDarkMode) {
      instanceViewport.style.backgroundColor = '#14202E';
    } else {
      instanceViewport.style.backgroundColor = '#F1F4F9';
    }
  }
}

export function getPageInfo(index) {
  if (instance) {
    return instance.pageInfoForIndex(index);
  }
}

export function getPageTop(index, offset) {
  if (index > 0 && instance) {
    let top = offset;
    const pageZoom = instance.currentZoomLevel;
    const gap = 20;

    for (let i = 0; i < index; i++) {
      const pageInfo = instance.pageInfoForIndex(i);
      top += (pageInfo.height + gap) * pageZoom;
    }

    return top;
  } else {
    return offset;
  }
}

export function getPageHeight(index) {
  if (instance) {
    const pageZoom = instance.currentZoomLevel;

    const pageInfo = instance.pageInfoForIndex(index);
    return pageInfo.height * pageZoom;
  } else {
    return 0;
  }
}

export function getPageIndex() {
  if (instance) {
    return instance.viewState.currentPageIndex;
  } else {
    return 0;
  }
}

export function getPageWidth(index) {
  if (instance) {
    const pageZoom = instance.currentZoomLevel;

    const pageInfo = instance.pageInfoForIndex(index);
    return pageInfo.width * pageZoom;
  } else {
    return 0;
  }
}

export async function setScrollTop(top) {
  if (instanceScrollElement) {
    instanceScrollElement.scrollTop = top;
  }
}

export function getHighlightsContainer() {
  return instanceHighlightContainer;
}

export function getScrollElement() {
  return instanceScrollElement;
}

export function addScrollListener(listener, options) {
  instanceScrollElement.addEventListener('scroll', listener, options);
}

export function removeScrollListener(listener) {
  instanceScrollElement?.removeEventListener('scroll', listener);
}

export function setZoom(zoom) {
  if (instance) {
    instance.setViewState(state => state.set('zoom', zoom));
  }
}

export function unload() {
  if (instance) {
    instance.removeEventListener('viewState.change', viewStateHandler);
    instance.removeEventListener('viewState.zoom.change', zoomHandler);

    importPSPDFKIT().then(PSPDFKit => {
      PSPDFKit.unload(instance);
      instance = null;
      instanceScrollElement = null;
      instanceZoomElement = null;
      instanceViewport = null;
    });
  }

  if (unsubscribeFromStore) unsubscribeFromStore();
}

function getTransformValues(element) {
  const style = window.getComputedStyle(element);
  const matrix = new DOMMatrixReadOnly(style.transform);

  return {
    x: matrix.m41,
    y: matrix.m42,
    z: matrix.m43
  };
}

// Currently, resizing the window does not trigger a zoom event despite the fact that the zoom level changes.
// This is a workaround to manually trigger the zoom event.
export const notifyZoomEventManually = () => {
  if (!instance) return;
  zoomHandler(instance.currentZoomLevel);
};
