import { auth } from 'lib/speechify';
import { actions as authActions } from 'store/auth';
import { actions as teamActions } from 'store/team';

import { AnyAction, Dispatch } from '@reduxjs/toolkit';

const teamMiddleware =
  ({ dispatch }: $TSFixMe) =>
  (next: Dispatch<AnyAction>) =>
  async (action: $TSFixMe) => {
    if (action.type === authActions.setUser.pending.type) {
      const uid = action.meta?.arg?.uid;
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const idToken = await auth.currentUser.getIdToken();

      if (uid) {
        const res: $TSFixMe = await fetch(`/api/team`, {
          method: 'GET',
          cache: 'no-cache',
          headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' }
        });
        if (res.status === 200) {
          const team = await res.json();
          if (team?.success) {
            dispatch(teamActions.set(team));
          } else {
            dispatch(teamActions.clear());
          }
        } else {
          dispatch(teamActions.clear());
        }
      }
    }

    next(action);
  };

export default teamMiddleware;
