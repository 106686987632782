import { AutoSkipContentUnlockedReason } from 'components/newListeningExperience/settings/Settings';
import { UseListenableContentItemIdState } from 'modules/listening/hooks/useListenableContentItemId';

import { AuthStoreState, useAuthStore } from 'modules/auth/store/authStore';
import { getIsAnonymous } from 'modules/auth/store/selectors/getIsAnonymous';
import { ListeningModeStoreState, ListeningOwnership, useListeningModeStore } from 'modules/listening/stores/listeningMode/listeningModeStore';
import { SubscriptionStoreState, useSubscriptionStore } from '../subscriptionStore';

type SkipContentUnlockedResult = {
  skipContentUnlocked: boolean;
  skipContentUnlockedReason?: AutoSkipContentUnlockedReason;
};

export const getIsSkipContentUnlocked = (
  itemIdState: UseListenableContentItemIdState,
  {
    subscriptionStoreState,
    listeningModeStoreState,
    authStoreState
  }: {
    subscriptionStoreState?: SubscriptionStoreState;
    listeningModeStoreState?: ListeningModeStoreState;
    authStoreState?: AuthStoreState;
  }
): SkipContentUnlockedResult => {
  subscriptionStoreState ??= useSubscriptionStore.getState();
  listeningModeStoreState ??= useListeningModeStore.getState();
  authStoreState ??= useAuthStore.getState();

  if (subscriptionStoreState.isPremium) {
    return {
      skipContentUnlocked: true,
      skipContentUnlockedReason: AutoSkipContentUnlockedReason.Premium
    };
  }

  if (!itemIdState.isLoading && subscriptionStoreState.firstPdfItemId === itemIdState.itemId) {
    return {
      skipContentUnlocked: true,
      skipContentUnlockedReason: AutoSkipContentUnlockedReason.FreeFirstDocument
    };
  }

  const isAnonymous = getIsAnonymous(authStoreState);
  if (isAnonymous && listeningModeStoreState.ownership === ListeningOwnership.SHARED) {
    // TODO(albertusdev): Only limit first document for shared listening mode.
    return {
      skipContentUnlocked: true,
      skipContentUnlockedReason: AutoSkipContentUnlockedReason.FreeFirstDocument
    };
  }

  return {
    skipContentUnlocked: false
  };
};
