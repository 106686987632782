import React, { useMemo } from 'react';

import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { navStoreActions } from 'modules/listening/navStore';
import { useLatestListenableContentTitle } from 'modules/sdk/hooks/useLatestListenableContentTitle';
import { ListenableContent } from 'modules/sdk/lib';
import { ContentMetaType } from 'modules/sdk/lib/facade/listenableContent/base';

import DeleteIcon from '../icons/DeleteIcon';
import DownloadMp3Icon from '../icons/DownloadMp3Icon';
import { DocIcon, EpubIcon, PdfIcon, ScanIcon, TxtIcon, WebIcon } from '../icons/FileTypeIcons';
import MoveIcon from '../icons/MoveIcon';
import OpenOriginalIcon from '../icons/OpenOriginalIcon';
import RenameIcon from '../icons/RenameIcon';

type FileActions = 'delete' | 'downloadMP3' | 'move' | 'original' | 'rename';

export type FileActionItemProps = JSX.IntrinsicElements['button'] &
  React.PropsWithChildren<{
    icon: JSX.Element;
  }>;

const FileActionItem = ({ icon, children, ...rest }: FileActionItemProps) => {
  return (
    <button
      className="flex items-center gap-spl-4 py-spl-5 px-spl-7 bg-surface-primary hover:bg-surface-primary-hovered active:bg-surface-primary-pressed focus-within:bg-surface-primary-hovered rounded-lg focus:outline-none"
      {...rest}
    >
      <div className="w-spl-8 h-spl-8 text-icon-secondary">{icon}</div>
      <div className="text-text-primary text-body-6">{children}</div>
    </button>
  );
};

type FileActionProps = {
  listenableContent: ListenableContent;
  onActionClick?: (action: FileActions) => void;
  showDownloadMp3?: boolean;
};

const FileAction = ({ listenableContent, onActionClick, showDownloadMp3 }: FileActionProps) => {
  const title = useLatestListenableContentTitle(listenableContent);
  const isPDF = listenableContent.isPDF();

  const metaAttributes = useMemo(() => {
    const attributes = [];

    // TODO: word count

    // page count
    if (isPDF) {
      attributes.push(`${PSPDFKitFacade.singleton.instance?.totalPageCount} page${PSPDFKitFacade.singleton.instance?.totalPageCount === 1 ? '' : 's'}`);
    }

    attributes.push(`${listenableContent.metaType}`);

    return attributes;
  }, [listenableContent?.metaType, isPDF]);

  const handleActionClick = (action: FileActions) => {
    switch (action) {
      case 'delete':
        navStoreActions.setModalState('delete');
        break;

      case 'downloadMP3':
        if (!showDownloadMp3) break;
        navStoreActions.setModalState('downloadMp3');
        break;

      case 'move':
        navStoreActions.setModalState('move');
        break;

      case 'original':
        if (isPDF) {
          if ('sourceUrl' in listenableContent && listenableContent.sourceUrl) {
            window.open(listenableContent.sourceUrl, '_blank');
          }
        } else if ('originalSourceUrl' in listenableContent && listenableContent.originalSourceUrl) {
          window.open(listenableContent.originalSourceUrl, '_blank');
        }

        break;

      case 'rename':
        navStoreActions.setModalState('rename');
        break;
    }

    if (onActionClick) onActionClick(action);
  };

  const renderDocumentTypeIcon = () => {
    switch (listenableContent.metaType) {
      case ContentMetaType.DOCX:
        return <DocIcon />;

      case ContentMetaType.EPUB:
        return <EpubIcon />;

      case ContentMetaType.HTML:
        return <WebIcon />;

      case ContentMetaType.PDF:
        return <PdfIcon />;

      case ContentMetaType.SCAN:
        return <ScanIcon />;

      case ContentMetaType.TXT:
        return <TxtIcon />;
    }

    return null;
  };

  const showOriginal = useMemo(() => {
    if (isPDF) {
      return 'sourceUrl' in listenableContent && listenableContent.sourceUrl;
    } else {
      return 'originalSourceUrl' in listenableContent && listenableContent.originalSourceUrl;
    }
  }, [isPDF, listenableContent]);

  return (
    <div className="flex flex-col rounded-200 border-s border-border-primary bg-bg-primary w-[344px] shadow-spl-100">
      <div className="flex px-spl-7 rounded-t-200 py-spl-6 gap-spl-6 border-b-s border-border-primary bg-bg-secondary">
        <div>{renderDocumentTypeIcon()}</div>
        <div className="grow flex flex-col">
          <div className="text-heading-6 text-text-primary text-left text-ellipsis whitespace-nowrap max-w-[260px] overflow-hidden">{title}</div>
          <div className="text-text-secondary text-body-7 text-ellipsis text-left">{metaAttributes.join(' • ')}</div>
        </div>
      </div>
      <div className="p-1 flex flex-col justify-center self-stretch">
        <FileActionItem icon={<RenameIcon />} onClick={() => handleActionClick('rename')}>
          Rename
        </FileActionItem>
        {showDownloadMp3 ? (
          <FileActionItem icon={<DownloadMp3Icon fillClass="fill-icon-secondary" />} onClick={() => handleActionClick('downloadMP3')}>
            Download MP3
          </FileActionItem>
        ) : null}
        <FileActionItem icon={<MoveIcon />} onClick={() => handleActionClick('move')}>
          Move Document
        </FileActionItem>
        {showOriginal && (
          <FileActionItem icon={<OpenOriginalIcon />} onClick={() => handleActionClick('original')}>
            Open Original
          </FileActionItem>
        )}
        <FileActionItem icon={<DeleteIcon />} onClick={() => handleActionClick('delete')}>
          Delete
        </FileActionItem>
      </div>
    </div>
  );
};

export default FileAction;
