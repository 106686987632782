import { useEffect } from 'react';

import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';

import { type ListeningScreenTabs, navStoreActions, useNavStore } from '../navStore';

const handleTabClick = (tab: ListeningScreenTabs) => {
  const activeTab = useNavStore.getState().activeTab;
  if (tab === 'search' && activeTab === 'search') {
    // toggle search to off
    navStoreActions.clearActiveTab();
    PSPDFKitFacade.singleton.hideSearchUI();
  } else if (tab !== 'search') {
    PSPDFKitFacade.singleton.hideSearchUI();
  }

  navStoreActions.toggleActiveTab(tab);
};

export function useActiveTab(isPDF: boolean) {
  const activeTab = useNavStore(state => state.activeTab);

  useEffect(() => {
    if (!isPDF) return;
    // Handle close via the external PSPDFKit close button
    // Open is already handled via click and our custom keyboard shortcuts.
    return PSPDFKitFacade.singleton.addIsSearchActiveListener((isSearchActive: boolean) => {
      if (!isSearchActive && useNavStore.getState().activeTab === 'search') {
        navStoreActions.clearActiveTab();
      }
    });
  }, [isPDF]);

  useEffect(() => {
    if (activeTab !== 'search') {
      PSPDFKitFacade.singleton.hideSearchUI();
    }
  }, [activeTab]);

  return { activeTab, handleTabClick };
}
