import { ErrorSource } from 'constants/errors';
import { logError } from 'lib/observability';
import { isVoiceEquivalent, Voice } from 'lib/speechify/voices';
import { RootState } from 'store';
import { ExtensionMessageEmitter, getUserVoiceSelectionHistory } from 'utils/extension';

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const name = 'voiceSelection';

const VOICE_SELECTION_HISTORY_STORAGE_KEY = 'voiceSelectionHistory';
const MAX_RECENT_VOICES: number = 10;

type VoiceSelectionState = {
  isOpen: boolean;
  history: Partial<Voice>[]; // for Recently tab
  hasSelectedVoice: boolean;
};

const DEFAULT_INITIAL_STATE: VoiceSelectionState = {
  isOpen: false,
  history: [],
  hasSelectedVoice: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState: (): VoiceSelectionState => {
    const history = getFallbackHistory() || [];
    const hasSelectedVoice = history.length > 0;
    return {
      ...DEFAULT_INITIAL_STATE,
      // @ts-expect-error TS(2322): Type '(Voice | undefined)[]' is not assignable to ... Remove this comment to see the full error message
      history,
      hasSelectedVoice
    };
  },
  reducers: {
    toggleVoiceSelection: state => {
      state.isOpen = !state.isOpen;
    },
    setHistory: (state, action: PayloadAction<Partial<Voice>[]>) => {
      state.history = action.payload;
      state.hasSelectedVoice = action.payload && action.payload.length > 0;
    },
    setHistoryFromExtension: (state, action: PayloadAction<Partial<Voice>[]>) => {
      // filter out personal voices in current history
      const personalVoices = state.history.map((item, index) => ({ item, index })).filter(({ item }) => item.name && item.name.startsWith('PVL'));

      // slot personal voices into payload at the correct indexes
      const newHistory = [...action.payload];

      personalVoices.forEach(({ item, index }) => {
        if (index === 0) {
          // if a personal voice was originally at the first slot, push it to the second slot or the end if newHistory has only one item
          if (newHistory.length > 1) {
            newHistory.splice(1, 0, item);
          } else {
            newHistory.push(item);
          }
        } else if (index <= newHistory.length) {
          newHistory.splice(index, 0, item);
        } else {
          newHistory.push(item);
        }
      });

      state.history = newHistory;
      state.hasSelectedVoice = action.payload && action.payload.length > 0;
    }
  }
});

const getFallbackHistory = (): Partial<Voice[]> => {
  let recently: Partial<Voice[]> = [];
  if (typeof window === 'undefined') return recently;
  try {
    recently = JSON.parse(window?.localStorage.getItem(VOICE_SELECTION_HISTORY_STORAGE_KEY) || '[]');
  } catch (e) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(e, ErrorSource.PLAYBACK);
    // ignore
    recently = [];
  }
  return recently;
};

let unsubscribe: () => void = () => {};

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onChangeVoice = createAsyncThunk<any, Partial<Voice & { languageCode: string }>, { state: RootState }>(
  'voiceSelection/onChangeVoice',
  async (payload, { dispatch, getState }) => {
    const voice = { ...payload, language: payload?.languageCode };
    const state = getState();
    const history = state.voiceSelection.history;
    const newHistory = history.filter(v => !isVoiceEquivalent(v, voice));

    newHistory.unshift(voice);
    newHistory.splice(MAX_RECENT_VOICES);
    localStorage.setItem(VOICE_SELECTION_HISTORY_STORAGE_KEY, JSON.stringify(newHistory));

    dispatch(generatedActions.setHistory(newHistory));
  }
);

// ESLint: '_' is assigned a value but never used & 'getState' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars
export const initHistory = createAsyncThunk('voiceSelection/initHistory', async (_ = undefined, { dispatch, getState, rejectWithValue }) => {
  try {
    unsubscribe();

    // the setHistory event is dispatched when user has Extension installed regardless whether user select the voice through web app or Extension
    unsubscribe = ExtensionMessageEmitter.on('setHistory', ({ history }) => {
      dispatch(generatedActions.setHistoryFromExtension(history));
    });

    const extensionHistory = await getUserVoiceSelectionHistory();

    if (extensionHistory) {
      dispatch(generatedActions.setHistoryFromExtension(extensionHistory));
    } else {
      const fallbackHistory = getFallbackHistory() as Partial<Voice>[];

      if (fallbackHistory) {
        dispatch(generatedActions.setHistory(fallbackHistory));
      }
    }

    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.PLAYBACK);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

// ESLint: '_' is assigned a value but never used & 'getState' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars
export const refreshHistory = createAsyncThunk('voiceSelection/refreshHistory', async (_ = undefined, { dispatch, getState, rejectWithValue }) => {
  try {
    const extensionHistory = await getUserVoiceSelectionHistory();

    if (extensionHistory) {
      dispatch(generatedActions.setHistoryFromExtension(extensionHistory));
    } else {
      const fallbackHistory = getFallbackHistory() as Partial<Voice>[];

      if (fallbackHistory) {
        dispatch(generatedActions.setHistory(fallbackHistory));
      }
    }

    return;
  } catch (error) {
    logError(error as Error, ErrorSource.PLAYBACK);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const isVoiceSelectionOpenSelector = createSelector(
  (state: RootState) => state.voiceSelection,
  (voiceSelectionState: VoiceSelectionState) => voiceSelectionState.isOpen
);

const actions = { ...generatedActions, initHistory, refreshHistory, onChangeVoice };

const selectors = { getIsVoiceSelectionOpen: isVoiceSelectionOpenSelector };

export { actions, selectors };

export default reducer;
