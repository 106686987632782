import { ErrorSource } from 'constants/errors';
import { logError } from 'lib/observability';
import { SDKVoiceFacade, VoiceInfo } from 'modules/sdk/lib';

import { useVoiceStore } from '../voicesStore';

export const deletePersonalVoice = async (voiceInfo: VoiceInfo) => {
  try {
    useVoiceStore.setState({
      personalVoices: useVoiceStore.getState().personalVoices.filter(voice => voice.id !== voiceInfo.id)
    });
    await SDKVoiceFacade.singleton.deletePersonalVoice(voiceInfo);
  } catch (e) {
    logError(new Error(`Error deletePersonalVoice: ${e}`), ErrorSource.PERSONAL_VOICES, {
      context: {
        operation: 'delete_personal_voice',
        voiceId: voiceInfo.id
      }
    });
  }
};
