import { IRecord } from 'interfaces';
import { chatAi, summarizeAi } from 'lib/api';
import { ChatMessage } from 'pages/api/ask-ai/chat';
import { getItemContent, getItemContentBetweenIndexes, SummaryLength, SummaryMode } from 'utils/askAi';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const summarize = createAsyncThunk(
  'askAiV2/summarize',
  async ({
    item,
    mode,
    id,
    length,
    allPages,
    pageIndexes
  }: {
    item: IRecord;
    id: string;
    mode: SummaryMode;
    length: SummaryLength;
    allPages: boolean;
    pageIndexes: number[];
  }) => {
    const content = allPages ? await getItemContent(item) : await getItemContentBetweenIndexes(item, pageIndexes);
    const { answer } = await summarizeAi({
      content,
      id,
      length,
      mode
    });
    return { item, answer };
  }
);

export const chat = createAsyncThunk(
  'askAiV2/chat',
  async ({ item, id, messages, content }: { item: IRecord; content: string; messages: ChatMessage[]; id: string }) => {
    const text = await getItemContent(item);
    const { answer } = await chatAi({
      content: text,
      messages: [...messages, { role: 'user', content, id }]
    });
    return { item, answer };
  }
);
