import { IGamification } from 'interfaces';
import { isNull, isUndefined } from 'lodash';
import { RootState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getGamificationState = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.gamification.gamification
);

export const getCurrentStreak = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.gamification.gamification?.currentStreak || 0
);

export const getStreakGoalInDays = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.gamification.gamification?.streakGoalInDays
);

export const getDailyListeningGoal = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.gamification.gamification?.dailyListeningGoalInMinutes
);

export const getSelectedDailyListeningGoal = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.gamification.selectedDailyListeningGoal
);

export const getTotalListeningDurationInMinutes = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.gamification.gamification?.totalListeningDurationInMinutes || 0
);

export const shouldShowStreakChangeNotification = createSelector(
  (state: RootState) => state.gamification.gamification,
  (state: IGamification | null) => {
    if (!state) return false;

    if (state.currentStreak === 1 && (isNull(state.streakGoalInDays) || isUndefined(state.streakGoalInDays))) {
      return true;
    }

    if (state.currentStreak === 2) return true;
    if (state.streakGoalInDays && state.currentStreak === state.streakGoalInDays) return true;

    return false;
  }
);

export const isGamificationEnabled = (state: RootState) => state?.gamification?.isEnabled;
