type Await<T> = T extends PromiseLike<infer U> ? U : T;

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export type MemoizedFunction<T extends (...args: any[]) => any> = T & {
  invalidateCache: (args?: Parameters<T>) => void;
  setCache: ({ args, key, value }: { args?: Parameters<T>; key?: string; value: ReturnType<T> }) => void;
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export type MemoizedAsyncFunction<T extends (...args: any[]) => Promise<any>> = T & {
  invalidateCache: (args?: Parameters<T>) => void;
  setCache: ({ args, key, value }: { args?: Parameters<T>; key?: string; value: Await<ReturnType<T>> }) => void;
  getCacheSync: ({ args, key }: { args?: Parameters<T>; key?: string }) => Await<ReturnType<T>>;
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export type MemoizeOptions<T extends (...args: any[]) => any> = {
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  argsToKey?: (...args: Parameters<T>) => any;
  timeout?: number;
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export type MemoizeAsyncOptions<T extends (...args: any[]) => any> = {
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  argsToKey?: (...args: Parameters<T>) => any;
  timeout?: number;
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export const memoize = <T extends (...args: any[]) => any>(
  callback: T,
  { argsToKey = () => 'key', timeout }: MemoizeOptions<T> = {}
): T & MemoizedFunction<T> => {
  let cache = new Map();
  const callbackWithCache = (...args: Parameters<T>): ReturnType<T> => {
    const key = argsToKey(...args);
    if (key === undefined || key === null) {
      throw new Error('Cache: argsToKey did not return a valid key: ' + key);
    }

    if (!cache.has(key) || (timeout && performance.now() - cache.get(key).createdAt > timeout)) {
      cache.set(key, { value: callback(...args), createdAt: performance.now() });
    }

    return cache.get(key).value;
  };
  callbackWithCache.invalidateCache = (...args: Parameters<T>) => {
    if (args.length === 0) {
      cache = new Map();
      return;
    }
    const key = argsToKey(...args);
    cache.delete(key);
  };
  callbackWithCache.setCache = ({ args, key, value }: { args: Parameters<T>; key?: string; value: ReturnType<T> }) => {
    if (key === undefined) key = argsToKey(...(args ?? []));
    cache.set(key, { value, createdAt: performance.now() });
  };

  return callbackWithCache as unknown as T & MemoizedFunction<T>;
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export const memoizeAsync = <T extends (...args: any[]) => Promise<any>>(
  callback: T,
  { argsToKey = () => 'key', timeout }: MemoizeAsyncOptions<T> = {}
): T & MemoizedAsyncFunction<T> => {
  let cache = new Map();

  const callbackWithCache = async (...args: Parameters<T>): Promise<ReturnType<T>> => {
    const key = await Promise.resolve(argsToKey(...(args as Parameters<T>)));
    if (key === undefined || key === null) {
      throw new Error('Cache: argsToKey did not return a valid key: ' + key);
    }

    if (!cache.has(key) || (timeout && performance.now() - cache.get(key).createdAt > timeout)) {
      const promise = callback(...args).then(result => {
        if (cache.get(key)?.value === promise) cache.set(key, { ...cache.get(key), value: result });
        return result;
      });

      cache.set(key, {
        value: promise,
        createdAt: performance.now()
      });
    }

    return cache.get(key).value;
  };

  callbackWithCache.invalidateCache = (...args: Parameters<T>) => {
    if (args.length === 0) {
      cache = new Map();
      return;
    }
    const key = argsToKey(...args);
    cache.delete(key);
  };
  callbackWithCache.setCache = ({ args, key, value }: { args: Parameters<T>; key?: string; value: Await<ReturnType<T>> }) => {
    if (!key) key = argsToKey(...(args ?? []));
    cache.set(key, { value, createdAt: performance.now() });
  };

  callbackWithCache.getCacheSync = (({ args, key } = {}) => {
    if (!key) key = argsToKey(...((args ?? []) as Parameters<T>));
    if (cache.get(key)?.value instanceof Promise) return undefined;
    return cache.get(key)?.value;
  }) as MemoizedAsyncFunction<T>['getCacheSync'];

  return callbackWithCache as unknown as T & MemoizedAsyncFunction<T>;
};
