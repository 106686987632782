import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOp = () => {};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOpAsync = async () => {};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (effect: () => void | (() => void)) => useEffect(effect, []);

/**
 * Equivalent to Kotlin's [`apply`](https://kotlinlang.org/api/latest/jvm/stdlib/kotlin/apply.html).
 * Use for initializing an object with extra code without having to create a variable for it.
 */
export function applyOn<T>(value: T, ...actions: Array<(value: T) => void>): T {
  for (const action of actions) {
    action(value);
  }
  return value;
}
