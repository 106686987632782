import { ErrorSource } from 'constants/errors';
import { logError } from 'lib/observability';
import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { Callback } from 'lib/speechify/adaptors/lib/typeAliases';
import type { Instance, OutlineElement } from 'pspdfkit';

import {
  AbstractPDFAdapterFactory,
  AbstractPDFDocumentAdapter,
  AbstractPDFPageAdapter,
  PDFDocumentAdapter,
  PDFDocumentMetadata,
  PDFOutline,
  PDFPageAdapter,
  PDFPageImageOptions,
  PDFPageMetadata,
  PDFPageTextContentItem
} from '@speechifyinc/multiplatform-sdk/api/adapters/pdf';
import {
  BinaryContentReadableRandomly,
  BinaryContentWithMimeTypeFromNativeReadableInChunks,
  binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob,
  Result,
  SDKError
} from '@speechifyinc/multiplatform-sdk/api/util';
import { BoundingBoxUtils, Viewport } from '@speechifyinc/multiplatform-sdk/api/util/images';

const getPageThumbnail = async (
  pdfDocument: Instance,
  pageIndex: number,
  scale = 1,
  width = 1024
): Promise<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> => {
  const blobUrl: string = await pdfDocument.renderPageAsImageURL({ width: width * scale }, pageIndex);
  const binaryContent = binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob(await urlToBlob(blobUrl));
  return binaryContent;
};

const getPageOutline = async (): Promise<PDFOutline> => {
  if (!PSPDFKitFacade.singleton.instance) {
    throw new Error('PSPDFKit instance not loaded');
  }
  const outline = await PSPDFKitFacade.singleton.instance.getDocumentOutline();
  const entries = convertOutlineToEntries(outline.toArray());
  return new PDFOutline(entries);
};

function convertOutlineToEntries(outline: OutlineElement[], level: number = 0): PDFOutline.Entry[] {
  return outline.flatMap(element => {
    const entry = new PDFOutline.Entry(element.title, level, new PDFOutline.Entry.Attributes(element.action?.pageIndex ?? 0));

    const children = element.children ? convertOutlineToEntries(element.children.toArray(), level + 1) : [];
    return [entry, ...children];
  });
}

const urlToBlob = async (blobURL: string): Promise<Blob> => {
  try {
    // Fetch the Blob data from the URL
    const response = await fetch(blobURL);

    if (!response.ok) {
      throw new Error('Failed to fetch Blob data');
    }

    // Get the Blob data
    const blobData = await response.blob();

    return blobData;
  } catch (error) {
    logError(error as Error, ErrorSource.READING_EXPERIENCE, {
      context: {
        url: blobURL
      }
    });
    return new Blob([], { type: '' });
  }
};

class WebPDFDocumentAdapter extends AbstractPDFDocumentAdapter {
  private pages: PDFPageAdapter[] = [];

  async getPages(pageIndexes: number[], callback: Callback<PDFPageAdapter[]>) {
    const pages = pageIndexes.map(index => {
      if (this.pages[index]) {
        return this.pages[index];
      }

      const adapter = new WebPDFPageAdapter(index);
      this.pages[index] = adapter;

      return adapter;
    });

    callback(new Result.Success(pages));
  }

  getMetadata(): PDFDocumentMetadata {
    return new PDFDocumentMetadata(PSPDFKitFacade.singleton.instance!.totalPageCount);
  }

  override get title(): string {
    // TODO(albertusdev): Pass on the title either from PSPDFKit instance or the global item store here.
    return 'New Book';
  }

  override getThumbnail = async (callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>): Promise<void> => {
    callback(new Result.Success(await getPageThumbnail(PSPDFKitFacade.singleton.instance!, 0)));
  };

  override getOutline = async (callback: Callback<PDFOutline>): Promise<void> => {
    callback(new Result.Success(await getPageOutline()));
  };

  destroy() {
    PSPDFKitFacade.singleton.unload();
  }
}

class WebPDFPageAdapter extends AbstractPDFPageAdapter {
  constructor(private readonly index: number) {
    super();
  }

  get pageIndex() {
    return this.index;
  }

  get instance(): Instance {
    const pspdfkitInstance = PSPDFKitFacade.singleton.instance;
    if (!pspdfkitInstance) {
      throw new Error('PSPDFKit instance not loaded');
    }
    return pspdfkitInstance;
  }

  async getTextContent(callback: Callback<PDFPageTextContentItem[]>) {
    const textLines = await this.instance.textLinesForPageIndex(this.index);

    callback(
      new Result.Success(
        textLines
          .map(line => {
            const { height, left, top, width } = line.boundingBox;
            return new PDFPageTextContentItem(
              line.contents, //
              BoundingBoxUtils.fromDimensionsAndCoordinates(width, height, left, top),
              `${line.id}` // TODO(sdk-pain-point): (albertusdev) Request better API from SDK team to pass metadata here for easier mapping
            );
          })
          .toArray()
      )
    );
  }

  getMetadata() {
    const pageInfo = this.instance.pageInfoForIndex(this.index);
    return new PDFPageMetadata(new Viewport(pageInfo?.width || 0, pageInfo?.height || 0));
  }

  destroy() {}

  async getImage(options: PDFPageImageOptions, callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>) {
    callback(new Result.Success(await getPageThumbnail(PSPDFKitFacade.singleton.instance!, this.pageIndex, options.scale, this.getMetadata().viewport.width)));
  }
}

export class WebPDFAdapterFactoryV2 extends AbstractPDFAdapterFactory {
  async getPDFDocumentAdapter(file: BinaryContentReadableRandomly, callback: Callback<PDFDocumentAdapter>) {
    try {
      await PSPDFKitFacade.instanceReady;
      callback(new Result.Success(new WebPDFDocumentAdapter()));
    } catch (e) {
      logError(e as Error, ErrorSource.READING_EXPERIENCE);
      callback(new Result.Failure(new SDKError.OtherException(e as Error)));
    }
  }
}
