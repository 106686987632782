import { ErrorSource } from 'constants/errors';
import { logError } from 'lib/observability';
import { SDKVoiceFacade, type VoiceInfo } from 'modules/sdk/lib';
import { store } from 'store';
import { actions as authActions } from 'store/auth';

const setPlaybackSpeed = (currentSpeed: number) => {
  try {
    store.dispatch(authActions.setPlaybackSpeed(currentSpeed));
  } catch (error) {
    logError(error as Error, ErrorSource.BACKWARDS_COMPATIBILITY, { context: { function: 'setPlaybackSpeed', speed: currentSpeed + '' } });
  }
};

const setVoice = (voice: VoiceInfo) => {
  const voiceSpec = SDKVoiceFacade.singleton.getVoiceSpec(voice);
  const voiceMeta = voiceSpec.toVoiceMetadata();
  const { engine, gender, languageCode } = voiceMeta;
  const { displayName, name } = voice;

  try {
    if (name) {
      store.dispatch(authActions.setVoice({ displayName, engine, gender: gender.name.toLowerCase(), languageCode, name }));
    }
  } catch (error) {
    logError(error as Error, ErrorSource.BACKWARDS_COMPATIBILITY, { context: { function: 'setVoice', voice: voice.id } });
  }
};

export default {
  setPlaybackSpeed,
  setVoice
};
