import { useCallback, useEffect, useRef, useState } from 'react';

import { BookView } from '@speechifyinc/multiplatform-sdk';
import type { BookPageTextContentItem } from '@speechifyinc/multiplatform-sdk/api/content/view/book';

import { promisify } from '../lib/speechify/adaptors/promisify';
import { RootState, useSelector } from '../store';

export default function useOcrUpsell(bookView?: BookView) {
  const [visible, setVisible] = useState(false);
  const [closed, setClosed] = useState(false);
  const isPremiumUser = useSelector((state: RootState) => state.auth.user?.entitlements?.isPremium || false);
  const shouldUpdate = useRef(true);

  const checkForOCRUpsell = useCallback(async (bookView: BookView) => {
    const pagesToCheck = Math.min(bookView.getMetadata().numberOfPages || 0, 5);
    const pageNumbers = Array.from(Array(pagesToCheck).keys());
    const pages = await promisify(bookView.getPages.bind(bookView))(pageNumbers);

    const content = await Promise.all(
      pages.map(page => {
        return new Promise<BookPageTextContentItem[] | null | undefined>(resolve => {
          page.getTextContent(textContent => {
            resolve(textContent.toNullable());
          });
        });
      })
    );
    const emptyPages = content.filter(pageContent => {
      return pageContent?.length === 0;
    });

    if (emptyPages.length / content.length > 0.5 && shouldUpdate.current) {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    if (bookView && !isPremiumUser) {
      checkForOCRUpsell(bookView);
    }
    // ESLint: React Hook useEffect has missing dependencies: 'checkForOCRUpsell' and 'isPremiumUser'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookView]);

  useEffect(
    () => () => {
      shouldUpdate.current = false;
    },
    []
  );

  return {
    ocrLocked: visible,
    ocrUpsellVisible: visible && !closed,
    closeOcrUpsell: () => {
      setClosed(true);
    }
  };
}
