import React from 'react';

import { BookReadingInfo } from 'components/experience/readers/newsdk/ReadingInfo';
import OCRUpsellModal from 'components/item/OcrUpsell';
import { WebAppListeningExperienceOverhaulVariant } from 'constants/featureDefinitions';
import useOcrUpsell from 'hooks/useOcrUpsell';
import { useSelector } from 'store';

export function OcrUpsell() {
  const user = useSelector(state => state.auth.user);
  const bookView = useSelector(state => {
    const instantListening = state.mainPage.instantListening;
    if (!instantListening) return undefined;
    if (instantListening.listeningOverhaulVariant !== WebAppListeningExperienceOverhaulVariant.CONTROL) {
      // The OCR Upsell for new listening screen is already handled on the new listening screen
      return undefined;
    }
    return instantListening?.details?.readingInfo instanceof BookReadingInfo ? instantListening.details.readingInfo.bookView : undefined;
  });

  const { ocrUpsellVisible, closeOcrUpsell } = useOcrUpsell(bookView);

  if (!user?.uid) return null;

  return <OCRUpsellModal onClose={closeOcrUpsell} open={ocrUpsellVisible} />;
}
