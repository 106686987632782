import assert from 'assert';
import { IRecord } from 'interfaces/library';
import { isCorsAllowed, wrapWithCorsProxy } from 'lib/speechify/adaptors/cors';

import { ContentMetaType } from '../../base';
import { BaseItemListenableContent } from '../BaseItemListenableContent';

export class PDFItemListenableContent extends BaseItemListenableContent {
  public readonly metaType: ContentMetaType = ContentMetaType.PDF;
  constructor(item: IRecord) {
    super(item);
  }

  content = async (): Promise<string> => {
    const url = this.sourceUrl;
    assert(url, 'PDFItemListenableContent: sourceUrl is null');
    if (isCorsAllowed(url)) return url;
    return wrapWithCorsProxy(url);
  };
}
