import React, { CSSProperties, Fragment, ReactNode, useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

interface ModalProps {
  children?: ReactNode;
  onClose?: () => void;
  open: boolean;
  showCloseButton?: boolean;
  classNames?: string;
  dialogClassNames?: string;
  style?: CSSProperties;
}

const ModalComponent: React.FC<ModalProps> = ({ children, onClose, showCloseButton, open = false, classNames = '', dialogClassNames = '', style = {} }) => {
  const [hidden, setHidden] = useState(!open);

  useEffect(() => {
    if (open) {
      setHidden(false);
    } else {
      const timeout = setTimeout(() => setHidden(true), 300);
      return () => clearTimeout(timeout);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`fixed inset-0 z-[60] overflow-y-visible ${dialogClassNames}`}
        open={open}
        hidden={hidden}
        // @ts-expect-error TS(2322): Type '(() => void) | undefined' is not assignable ... Remove this comment to see the full error message
        onClose={onClose}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#0D0D0FB2] transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              style={style}
              className={twMerge(
                `inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:align-middle`,
                `${classNames}`
              )}
            >
              {showCloseButton && (
                <XIcon className="float-right mx-4 mt-4 block h-5 w-5 cursor-pointer sm:h-6 dark:text-glass-350" aria-hidden="true" onClick={onClose} />
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalComponent;
