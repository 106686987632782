import { ErrorSource, LibraryErrorOperation } from 'constants/errors';
import { IRecord, ItemType, LibraryActionType } from 'interfaces';
import * as faro from 'lib/observability';
import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';
import { debounce } from 'lodash';
import getT from 'next-translate/getT';
import { actions as toastActions } from 'store/toast';
import { actions as usageActions } from 'store/usage';
import { logSegmentEvent } from 'utils/analytics';
import { itemTypeFilterFn } from 'utils/filter';
import { sortItemFn } from 'utils/sort';
import * as extension from 'utils/extension';
import { v4 as uuidv4 } from 'uuid';

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '../index';
import { actions } from './index';

let _unsubscribe = () => {};
let subscribed = false;

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let debouncedLogSegmentEvent: any = null;

export const addFolder = createAsyncThunk(
  LibraryActionType.addFolder,
  // ESLint: 'getState' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ title, parentFolderId }: { title: string; parentFolderId?: string }, { getState, rejectWithValue }) => {
    try {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const newFolderId = await speechify.createFolder(title, parentFolderId);

      logSegmentEvent('web_app_folder_created', {});

      return { id: newFolderId };
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, ErrorSource.LIBRARY, {
        context: {
          operation: LibraryErrorOperation.ADD_FOLDER,
          parentFolderId: parentFolderId || ''
        }
      });
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    }
  }
);

export const addInitialFiles = createAsyncThunk(LibraryActionType.addInitialFiles, async (_, { rejectWithValue }) => {
  try {
    await speechify.addDefaultLibraryItems();
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.ADD_INITIAL_FILES
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const addTextDocument = createAsyncThunk(
  LibraryActionType.addTextDocument,
  async (data: { text: string; title: string }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state: $TSFixMe = getState();

      const locale = state.app.locale;
      const t = await getT(locale, 'common');

      const title = data.title || data.text.split(' ').splice(0, 6).join(' ') + '...';
      const file = new File([data.text], `${uuidv4()}.txt`, { type: 'text/plain' });
      const folderId = state.library.currentFolderId;

      dispatch(
        toastActions.add({
          description: t('processing_document'),
          duration: 6000,
          title: t('Processing'),
          type: 'success'
        })
      );

      // @ts-expect-error TS(2345): Argument of type 'WebBoundaryMap<string | undefine... Remove this comment to see the full error message
      await speechify.importBlobByUpload(file, new speechify.ImportOptions(title, folderId, new speechify.WebBoundaryMap({})));

      return;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, ErrorSource.LIBRARY, {
        context: {
          operation: LibraryErrorOperation.ADD_TEXT_DOCUMENT
        }
      });
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    }
  }
);

// No longer in use, we now do instant listening.
export const addWebLink = createAsyncThunk(LibraryActionType.addWebLink, async (data: { url: string }, { dispatch, getState, rejectWithValue }) => {
  try {
    const state: $TSFixMe = getState();

    const locale = state.app.locale;
    const t = await getT(locale, 'common');

    await faro.instrumentAction(async () => {
      const folderId = state.library.currentFolderId && state.library.currentFolderId !== 'root' ? state.library.currentFolderId : null;

      dispatch(
        toastActions.add({
          description: t('processing_document'),
          duration: 6000,
          title: t('Processing'),
          type: 'waiting'
        })
      );

      const itemId = (
        await speechify.importFileFromURL(data.url, new speechify.ImportOptions(null, folderId, new speechify.WebBoundaryMap({ prop: 'value' })), null)
      )?.uri.id;

      if (itemId) {
        logSegmentEvent('web_app_document_imported', { type: 'web_link' });
      }
    }, 'add-web-link');
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.ADD_WEB_LINK,
        url: data.url
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const archiveItem = createAsyncThunk<
  string | undefined,
  { itemId: string; isFolder?: boolean; noToasts?: boolean },
  { state: RootState; dispatch: AppDispatch }
>(LibraryActionType.archiveItem, async (data, { rejectWithValue, getState, dispatch }) => {
  try {
    if (getState().auth.user?.extensionInstalled && !data.isFolder) {
      extension.removeSavedItem(data.itemId);
    }

    await speechify.archiveItem(data.itemId);

    if (!data.noToasts) {
      const state: $TSFixMe = getState();

      const locale = state.app.locale;
      const t = await getT(locale, 'common');

      dispatch(
        toastActions.add({
          title: data.isFolder ? t('Folder has been moved successfully to Trash') : t('File has been moved successfully to Trash'),
          description: '',
          type: 'trash'
        })
      );
    }

    return data.itemId;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.ARCHIVE_ITEM,
        itemId: data.itemId
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const archiveItems = createAsyncThunk<string[] | undefined, string[], { state: RootState; dispatch: AppDispatch }>(
  LibraryActionType.archiveItems,
  async (itemIds: string[], { rejectWithValue, getState, dispatch }) => {
    try {
      if (getState().auth.user?.extensionInstalled) {
        for (const itemId of itemIds) {
          extension.removeSavedItem(itemId);
        }
      }

      await Promise.all(itemIds.map(itemId => speechify.archiveItem(itemId)));

      const state: $TSFixMe = getState();

      const locale = state.app.locale;
      const t = await getT(locale, 'common');

      dispatch(
        toastActions.add({
          title: t('Files have been moved successfully to Trash'),
          description: '',
          type: 'trash'
        })
      );

      return itemIds;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, ErrorSource.LIBRARY, {
        context: {
          operation: LibraryErrorOperation.ARCHIVE_ITEMS,
          itemIds: itemIds.join(',')
        }
      });
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    }
  }
);

export const deleteItem = createAsyncThunk(LibraryActionType.deleteItem, async (itemId: string, { rejectWithValue }) => {
  try {
    await speechify.deleteItem(itemId);
    return itemId;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.DELETE_ITEM,
        itemId
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const restoreItem = createAsyncThunk<
  string,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
  // @ts-expect-error TS(2345): Argument of type '(itemId: string, { rejectWithVal... Remove this comment to see the full error message
>(LibraryActionType.restoreItem, async (itemId: string, { rejectWithValue, getState, dispatch }) => {
  try {
    await speechify.restoreItem(itemId);
    const state = getState();
    const locale = state.app.locale;

    const t = await getT(locale, 'common');
    dispatch(
      toastActions.add({
        description: t('Item restored'),
        type: 'success'
      })
    );
    return itemId;
  } catch (error) {
    // @ts-expect-error TS2345: Argument of type 'unknown' is not assignable to parameter of type 'Error'.
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.RESTORE_ITEM,
        itemId
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const fetchItem = createAsyncThunk<IRecord, { itemId: string; parentFolderId?: string }>(
  LibraryActionType.fetchItem,
  async (data, { rejectWithValue }) => {
    try {
      return await speechify.fetchItem(data.itemId, data.parentFolderId);
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, ErrorSource.LIBRARY, {
        context: {
          operation: LibraryErrorOperation.FETCH_ITEM,
          itemId: data.itemId,
          parentFolderId: data.parentFolderId || ''
        }
      });
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    }
  }
);

export const fetchItemsCount = createAsyncThunk<
  number,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(LibraryActionType.fetchItemsCount, async (_, { rejectWithValue, getState }) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  const userId = getState().auth.user.uid;
  if (!userId) {
    throw new Error('User not logged in');
  }
  try {
    return await speechify.fetchAllRecordItemsOwnedByCurrentUser();
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.FETCH_SHARED_ITEM
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const fetchSharedItem = createAsyncThunk(LibraryActionType.fetchSharedItem, async (itemId: string, { rejectWithValue }) => {
  try {
    return await speechify.fetchSharedItem(itemId);
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.FETCH_PAGES,
        itemId
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const setName = createAsyncThunk('library/setName', async (data: { itemId: string; name: string; isFolder?: boolean }, { rejectWithValue }) => {
  try {
    await speechify.updateItem(data.itemId, new speechify.UpdateLibraryItemParams(data.name, null, null));
    return data;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.SET_NAME,
        itemId: data.itemId,
        name: data.name
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const setParentFolderId = createAsyncThunk(
  LibraryActionType.setParentFolderId,
  async (data: { itemId: string; parentFolderId: string; folderName: string; isFolder?: boolean }, { rejectWithValue, dispatch, getState }) => {
    try {
      await speechify.moveItem(data.itemId, data.parentFolderId);

      const state: $TSFixMe = getState();
      const locale = state.app.locale;
      const t = await getT(locale, 'common');

      let title: string;
      if (data.isFolder && data.folderName) {
        title = t('Folder has been moved successfully to folder', {
          folder: data.folderName
        });
      } else if (data.isFolder) {
        title = t('Folder has been moved successfully');
      } else if (data.folderName) {
        title = t('File has been moved successfully to folder', {
          folder: data.folderName
        });
      } else {
        title = t('File has been moved successfully');
      }

      dispatch(
        toastActions.add({
          title,
          description: '',
          type: 'success'
        })
      );

      return data;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, ErrorSource.LIBRARY, {
        context: {
          operation: LibraryErrorOperation.MOVE_ITEM,
          itemId: data.itemId,
          parentFolderId: data.parentFolderId
        }
      });
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    }
  }
);

export const moveItems = createAsyncThunk(
  LibraryActionType.moveItems,
  async (data: { itemIds: string[]; folderIds: string[]; parentFolderId: string; folderName: string }, { rejectWithValue, getState, dispatch }) => {
    try {
      await Promise.all([...data.folderIds, ...data.itemIds].map(itemId => speechify.moveItem(itemId, data.parentFolderId)));

      const state: $TSFixMe = getState();

      const locale = state.app.locale;
      const t = await getT(locale, 'common');

      dispatch(
        toastActions.add({
          title: t('Files have been moved successfully', {
            folder: data.folderName
          }),
          description: '',
          type: 'success'
        })
      );

      return data;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, ErrorSource.LIBRARY, {
        context: {
          operation: LibraryErrorOperation.MOVE_ITEMS,
          itemIds: data.itemIds.join(','),
          parentFolderId: data.parentFolderId
        }
      });
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    }
  }
);

export const setSearchQuery = createAsyncThunk(LibraryActionType.setSearchQuery, async (searchQuery: string, { rejectWithValue }) => {
  try {
    return { searchQuery };
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.SEARCH,
        searchQuery
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const searchArticles = createAsyncThunk(LibraryActionType.searchArticles, async (query: string, { rejectWithValue }) => {
  try {
    const searchResults = await speechify.searchItems(query);

    if (!debouncedLogSegmentEvent) {
      debouncedLogSegmentEvent = debounce(logSegmentEvent, 1000);
    }

    debouncedLogSegmentEvent('web_app_search_used', {});

    return { searchResults };
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.SEARCH,
        query
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const subscribe = createAsyncThunk<
  void,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(LibraryActionType.subscribe, async (folderId, { dispatch, getState, rejectWithValue }) => {
  try {
    const state = getState();

    if (!state.library.isInited) {
      dispatch(actions.setIsInited(true));
    }

    const rootFolderId = await speechify.getRootFolder();

    if (subscribed) _unsubscribe();

    dispatch(actions.setSyncing());

    subscribed = true;

    _unsubscribe = await speechify.subscribe(folderId || rootFolderId, (folders: IRecord[] | null, items: IRecord[] | null) => {
      if (!subscribed) return;

      dispatch(actions.setCacheFolderId(folderId || null));

      if (folders) {
        dispatch(actions.setFolders(folders));
      }

      if (items) {
        dispatch(actions.setItems(items));
        const sortBy = state.library.sort;
        const filterByType = state.library.filter;
        const filteredItems = items
          .filter(item => item.type === ItemType.Record)
          .filter(itemTypeFilterFn(filterByType, folderId))
          .sort(sortItemFn(sortBy));

        dispatch(actions.setItemCount(filteredItems.length));

        dispatch(
          usageActions.setUserHasReadAtLeastOneItem(items.some(item => item.listenProgressStatus === 'DONE' || item.listenProgressStatus === 'IN_PROGRESS'))
        );
      }

      dispatch(actions.clearSyncing());
    });

    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.LIBRARY, {
      context: {
        operation: LibraryErrorOperation.SUBSCRIBE_FOR_UPDATES,
        folderId
      }
    });
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const unsubscribe = () => {
  subscribed = false;
  _unsubscribe();
};
