import { Result, SDKError } from '@speechifyinc/multiplatform-sdk';
import { Callback } from './typeAliases';

/**
 * Convert JS idiomatic async function of `block` to the `callback`, making sure it always gets called, even if an exception occurred.
 * The function allows to use code blocks which use the JS async functions with their modern non-nested syntax.
 */
export function callbackFromAsync<SuccessVal>(block: () => Promise<SuccessVal>, callback: Callback<SuccessVal>) {
  (async () => {
    let result: SuccessVal;
    try {
      result = await block();
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // With Typescript 4.9 these casts to any are no longer required.
    } catch (error: $TSFixMe) {
      // Convert network errors to ConnectionError so telemetry can distinguish them from other errors.
      if (error instanceof Error && error.message === 'Network Error') {
        callback(new Result.Failure(new SDKError.ConnectionError(error.message)));
      } else if (
        'code' in error &&
        typeof error.code === 'string' &&
        error.code === 'storage/object-not-found' &&
        'message' in error &&
        typeof error.message === 'string'
      ) {
        callback(new Result.Failure(new SDKError.ResourceNotFound('', error.message)));
      } else {
        callback(new Result.Failure(new SDKError.OtherException(error as Error)));
      }
      return;
    }

    callback(new Result.Success(result));
  })();
}
