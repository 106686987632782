import { IUser } from 'interfaces';
import { logError } from 'lib/observability';
import { isEmpty } from 'lodash';
import type { Nullable } from 'utils/types';

import type { Entitlements } from '@speechifyinc/multiplatform-sdk';

import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';

export type EntitlementsInfo = {
  isPremium: boolean;
  hdWordsLeft: number;
  nextHdWordsGrant: number;
  nextHdWordsGrandDate?: Nullable<Date>;
  lastHdWordsGrantDate?: Nullable<Date>;
};

export class SDKSubscriptionFacade extends SDKFacade {
  private static _singleton: SDKSubscriptionFacade;

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKSubscriptionFacade._singleton = this;
  }

  static override get singleton(): SDKSubscriptionFacade {
    return SDKSubscriptionFacade._singleton;
  }

  hasSubscription = (user: Nullable<IUser>) => {
    return !isEmpty(user?.subscription);
  };

  isNewUser = (user: IUser, newUserThreshold: number = new Date('2024-08-01').getTime()) => {
    const { metadata } = user;

    if (!metadata?.creationTime) {
      return false;
    }

    const creationTime = new Date(metadata.creationTime).getTime();

    if (isNaN(creationTime)) {
      return false;
    }

    return creationTime >= newUserThreshold;
  };

  isPremium = (user: Nullable<IUser>) => {
    return this.hasSubscription(user) && user?.entitlements?.isPremium === true;
  };

  isStripe = (user: Nullable<IUser>) => {
    const SubscriptionSource = this.sdk.sdkModule.SubscriptionSource;
    return this.hasSubscription(user) && user?.subscription?.plan?.source?.name === SubscriptionSource.STRIPE.name;
  };

  isOnTrial = (user: Nullable<IUser>) => {
    return this.hasSubscription(user) && user?.subscription?.isOnTrial === true;
  };

  logHdWordsListened = this.sdk.promisify(this.sdk.client.subscriptionService.logHdWordsListened.bind(this.sdk.client.subscriptionService));

  public mapSDKEntitlementsToEntitlementsInfo = (entitlements: Entitlements | undefined): EntitlementsInfo => {
    if (!entitlements) {
      return {
        isPremium: false,
        hdWordsLeft: 0,
        nextHdWordsGrant: 0,
        nextHdWordsGrandDate: null,
        lastHdWordsGrantDate: null
      };
    }
    return {
      isPremium: entitlements.isPremium,
      hdWordsLeft: entitlements.hdWordsLeft,
      nextHdWordsGrant: entitlements.nextHDWordsGrant,
      nextHdWordsGrandDate: entitlements.nextHDWordsGrantDate ? new Date(entitlements.nextHDWordsGrantDate) : null,
      lastHdWordsGrantDate: entitlements.lastHdWordsGrantDate ? new Date(entitlements.lastHdWordsGrantDate) : null
    };
  };

  public getEntitlements = async (): Promise<EntitlementsInfo> => {
    const sdkGetEntitlementsFn = this.sdk.promisify(this.sdk.client.subscriptionService.getEntitlements.bind(this.sdk.client.subscriptionService));

    const sdkEntitlements = await sdkGetEntitlementsFn();
    return this.mapSDKEntitlementsToEntitlementsInfo(sdkEntitlements);
  };

  public listenToEntitlementsChange = (callback: (entitlements: EntitlementsInfo) => void) => {
    return this.sdk.client.subscriptionService.addEntitlementsChangeListener(result => {
      if (result instanceof this.sdk.sdkModule.Result.Success) {
        callback(this.mapSDKEntitlementsToEntitlementsInfo(result.value));
      } else if (result instanceof this.sdk.sdkModule.Result.Failure) {
        logError(new Error(`Failed to listen to entitlements change: ${result.error}`));
        return;
      }
    });
  };
}
