import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';

import { sideBannerActions, SideBannerType } from './sideBannerStore';

export const CSAT_SHOWN_STORAGE_KEY = 'feedbackShownV2';

const tryQueueCsatBanner = (currentWordCount: number) => {
  const progressFraction = usePlaybackStore.getState().latestPlaybackState?.latestProgressFraction ?? 0;
  const feedbackShownAlready = localStorage.getItem(CSAT_SHOWN_STORAGE_KEY);

  if (!feedbackShownAlready && progressFraction >= 0.95 && currentWordCount >= 500) {
    sideBannerActions.add({ type: SideBannerType.CSAT });
    localStorage.setItem(CSAT_SHOWN_STORAGE_KEY, 'true');
  }
};

export { tryQueueCsatBanner };
