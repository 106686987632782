import { ALLOWED_MIME_TYPES } from 'interfaces';
import type { Callback } from 'lib/speechify/adaptors/lib/typeAliases';

import type { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';

import { BaseListenableContent, ContentMetaType, ContentType } from './base';
import { FileListenableContent } from './blob';
import { ItemListenableContent } from './item';
import { ListenableContent } from './types';
import { URLListenableContent } from './url';

export type ImportCompleteCallback = Callback<SpeechifyURI>;

export type ImportableListenableContent = FileListenableContent | URLListenableContent;

export function isItemListenableContent(content: BaseListenableContent): content is ItemListenableContent {
  return content.contentType === ContentType.libraryItem;
}

export function isFileListenableContent(content: BaseListenableContent): content is FileListenableContent {
  return content.contentType === ContentType.file;
}

export function isURLListenableContent(content: BaseListenableContent): content is URLListenableContent {
  return content.contentType === ContentType.url;
}

export function isImportableListenableContent(content: ListenableContent): content is ImportableListenableContent {
  return isFileListenableContent(content) || isURLListenableContent(content);
}

export function shouldRelyOnSDKForDeterminingFileTitle(content: ListenableContent): boolean {
  const title = content.title;
  const hasTitle = title && title.length > 0;
  if (!hasTitle) {
    return true;
  }

  if (content.metaType === ContentMetaType.TXT) {
    // For TXT file, when the title is explicitly set, we should not rely on the SDK to determine the title
    // since TXT don't contain useful metadata to determine the title
    // returning true here will yield the first line as the title
    return false;
  }

  if (content.isPDF()) {
    // For PDF file, SDK parsing will always yield the first line as the title which is not really useful.
    return false;
  }

  if (content.metaType === ContentMetaType.EPUB) {
    // For EPUB file, we rely on the SDK to determine the title
    return true;
  }

  if (isURLListenableContent(content)) {
    // For URL based import, we rely on the SDK to determine the title
    return true;
  }

  return false;
}

export function listenableContentErrorContext(content: BaseListenableContent): Record<string, string> {
  const baseContext = {
    title: content.title,
    contentType: content.contentType
  };
  if (isItemListenableContent(content)) {
    return {
      ...baseContext,
      itemId: content.itemId
    };
  }
  return baseContext;
}

export const mimeTypeToMetaType = (mimeType: string): ContentMetaType => {
  switch (mimeType) {
    case ALLOWED_MIME_TYPES.PDF:
      return ContentMetaType.PDF;
    case ALLOWED_MIME_TYPES.EPUB:
      return ContentMetaType.EPUB;
    case ALLOWED_MIME_TYPES.HTML:
      return ContentMetaType.HTML;
    case ALLOWED_MIME_TYPES.TXT:
      return ContentMetaType.TXT;
    case ALLOWED_MIME_TYPES.DOCX:
      return ContentMetaType.DOCX;
    default:
      return ContentMetaType.UNKNOWN;
  }
};
