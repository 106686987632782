import type { VoiceSelectionTabs } from 'components/newListeningExperience/player/Screens/VoiceSelector';
import { SummaryDialogState } from 'modules/listening/components/ChatAskAiSDKbased';
import type { BooleanSettingKey, SkipContentAnalyticsTypes } from 'modules/listening/features/settings/settings';
import { ListeningScreenTabs } from 'modules/listening/navStore';
import { ContentMetaType, ContentMetaTypeLowercase } from 'modules/sdk/lib/facade/listenableContent/base';

import type { ListenToDocumentTriggeredFrom } from './impl/playbackAnalytics';

export enum AnalyticsEventKey {
  changedVoiceTab = 'web_app_changed_voice_tab',
  documentAskAi = 'web_app_document_ask_ai',
  documentAskAiAutoplayResponses = 'web_app_document_ask_ai_autoplay_responses',
  documentAskAiChatCleared = 'web_app_document_ask_ai_chat_cleared',
  documentAskAiClearChatModalOpened = 'web_app_document_ask_ai_clear_chat_modal_opened',
  documentAskAiCloseChat = 'web_app_document_ask_ai_close_chat',
  documentAskAiListenedToAnswer = 'web_app_document_ask_ai_listened_to_answer',
  documentAskAiCopyAnswer = 'web_app_document_ask_ai_copy_answer',
  documentCompleted = 'web_app_document_completed',
  documentDeleted = 'web_app_document_deleted',
  documentOpened = 'web_app_document_opened',
  documentOpenedDefault = 'web_app_default_document_opened',
  documentOpenedRelisten = 'web_app_document_opened_relisten',
  documentSummaryClicked = 'web_app_document_summary_clicked',
  helpCenterFeatureRequest = 'web_help_center_feature_request',
  helpCenterMessageUpgrade = 'web_help_center_message_upgrade',
  helpCenterReportBrokenExperience = 'web_help_center_report_broken_experience',
  homeButtonPressed = 'web_app_home_button_pressed',
  instantListeningUploadWarningModalConfirmButtonClicked = 'web_app_instant_listening_upload_warning_modal_confirm_button_clicked',
  instantListeningUploadWarningModalDismissButtonClicked = 'web_app_instant_listening_upload_warning_modal_dismiss_button_clicked',
  instantListeningUploadWarningModalShown = 'web_app_instant_listening_upload_warning_modal_shown',
  listeningFeedbackContent = 'listening_feedback_content',
  listeningFeedbackRating = 'listening_feedback_rating',
  listeningSettingsClicked = 'web_app_listening_settings_clicked',
  listeningSettingsSkipContentClicked = 'clicked_skip_content',
  listeningSettingsZoomChanged = 'web_app_listening_settings_zoom_changed',
  openedDocumentSettings = 'opened_document_settings',
  playerJumpBySentence = 'web_app_jump_by_sentence',
  playerListenToDocument = 'web_app_listen_to_document',
  playerSpeedChanged = 'web_app_speed_changed',
  playerSpeedRampChanged = 'web_app_speed_ramp_changed',
  playerVoiceChanged = 'web_app_voice_changed',
  speedPickerUpsell = 'web_app_speed_picker_upsell',
  switchedToFree = 'web_app_switched_to_free',
  topNavItemClicked = 'web_app_top_nav_item_clicked',
  unlimitedSpeedPopup = 'web_app_unlimited_speed_popup',
  upgradePressedAudioVisualCta = 'web_app_audio_visual_cta_upgrade_pressed',
  upgradePressedContextual = 'web_app_contextual_upsell_upgrade_clicked',
  upgradePressedContextualControl = 'web_app_contextual_upsell_control_upgrade_clicked',
  upgradePressedDashboard = 'web_app_dashboard_upgrade_clicked',
  upgradePressedOcrUpsell = 'web_app_ocr_upsell_upgrade_clicked',
  upsellModalsAudioVisualCtaClosed = 'web_app_audio_visual_cta_closed',
  upsellModalsContextual = 'web_app_contextual_upsell_shown',
  upsellModalsOcrUpsellShown = 'web_app_ocr_upsell_shown',
  usageWordsListened = 'web_app_usage_words_listened',
  usageWordsListenedGamification = 'web_app_usage_words_listened_gamification',
  basicTrialNotification = 'web_app_basic_trial_notification_upgrade',
  skipHeaderFooterUpsell = 'web_app_skip_header_footer_upsell',
  voiceSelector = 'web_app_voice_selector_upgrade',
  pdfLimitPopup = 'web_app_pdf_limit_popup',
  profoile = 'web_app_profile_upgrade',
  subscriptionSettings = 'web_app_subscription_settings_upgrade',
  sidebar = 'web_app_sidebar_upgrade',
  playerSettings = 'web_app_player_settings',
  speedControls = 'web_app_speed_controls_upgrade',
  voiceControls = 'web_app_voice_controls_upgrade',
  accountPage = 'web_app_account_page_upgrade',
  askAI = 'web_app_ask_ai_upgrade',
  downloadMp3 = 'web_app_download_mp3_upgrade',
  imageOnlyPDFs = 'web_app_image_only_pdfs_upgrade',
  premiumVoices = 'web_app_premium_voices_upgrade',
  unlimitedFiles = 'web_app_unlimited_files_upgrade'
}

type Properties<Context extends Record<string, unknown>> = {} & Context;

export type AnalyticsEventProperties = {
  [AnalyticsEventKey.documentAskAi]: Properties<
    | {
        action: 'chat';
        query: string;
      }
    | {
        action: 'summary_regenerate';
      }
    | {
        action: 'generate_summary';
        options: Omit<SummaryDialogState, 'open'>;
      }
  >;
  [AnalyticsEventKey.documentAskAiAutoplayResponses]: Properties<{
    state: 'on' | 'off';
  }>;
  [AnalyticsEventKey.documentAskAiListenedToAnswer]: Properties<{
    via: 'autoplay' | 'button';
  }>;
  [AnalyticsEventKey.changedVoiceTab]: Properties<{
    changedTabTo: VoiceSelectionTabs;
  }>;
  [AnalyticsEventKey.listeningSettingsSkipContentClicked]: Properties<{
    type: 'all' | SkipContentAnalyticsTypes;
    value: 'on' | 'off';
  }>;
  [AnalyticsEventKey.documentDeleted]: Properties<{
    type: ContentMetaTypeLowercase;
  }>;
  [AnalyticsEventKey.documentOpened]: Properties<{
    type: ContentMetaTypeLowercase;
  }>;
  [AnalyticsEventKey.documentOpenedRelisten]: Properties<{
    type: ContentMetaTypeLowercase;
  }>;
  [AnalyticsEventKey.documentSummaryClicked]: Properties<{
    state: 'collapse' | 'expanded';
    fileType: ContentMetaType;
    fileWordCount?: number;
  }>;

  [AnalyticsEventKey.helpCenterFeatureRequest]: Properties<{ featureRequest: string }>;

  [AnalyticsEventKey.helpCenterReportBrokenExperience]: Properties<{ feedback: string }>;

  [AnalyticsEventKey.homeButtonPressed]: Properties<{
    source: string;
  }>;
  [AnalyticsEventKey.listeningSettingsClicked]: Properties<{
    key: BooleanSettingKey;
    value: boolean;
  }>;
  [AnalyticsEventKey.listeningSettingsZoomChanged]: Properties<{
    zoomPercentage: number;
  }>;
  [AnalyticsEventKey.listeningFeedbackRating]: Properties<{
    sentiment: number;
    // In old FeedbackCsat.tsx, there is already a property called isNewListeningExperience
    // so I am giving this event a new property to distinguish
    isRehaul: boolean;
  }>;
  [AnalyticsEventKey.openedDocumentSettings]: Properties<{
    state: 'locked' | 'unlocked';
  }>;
  [AnalyticsEventKey.playerListenToDocument]: Properties<{
    triggeredFrom: ListenToDocumentTriggeredFrom;
    voiceId: string;
    voiceName: string;
    isHD: boolean;
    engine?: string;
    isClonedVoice?: boolean;
  }>;
  [AnalyticsEventKey.playerJumpBySentence]: Properties<{
    backward: boolean;
  }>;
  [AnalyticsEventKey.playerSpeedChanged]: Properties<{
    speed: number;
    wpm: number;
    source?: 'speedUI' | 'keyboardShortcut' | 'autoIncrease' | 'switchToFree' | 'switchToNonPremium';
  }>;
  [AnalyticsEventKey.playerSpeedRampChanged]: Properties<{
    value: boolean;
  }>;
  [AnalyticsEventKey.playerVoiceChanged]: Properties<{
    voiceId: string;
    voiceName: string;
    isHD: boolean;
    engine?: string;
  }>;
  [AnalyticsEventKey.topNavItemClicked]: Properties<{
    tab: ListeningScreenTabs | null;
  }>;
  [AnalyticsEventKey.usageWordsListened]: Properties<{
    is_highlighting_enabled: boolean;
    isEmbedded: boolean;
    isHD: boolean;
    voice_id: string;
    speed: number;
    isClonedVoice: boolean;
  }>;
  [AnalyticsEventKey.usageWordsListenedGamification]: Properties<{
    is_highlighting_enabled: boolean;
    isHD: boolean;
    voice_id: string;
    threshold: number;
    wpm: number;
    isGamificationEnabled: boolean;
    isClonedVoice: boolean;
  }>;
  [AnalyticsEventKey.upgradePressedDashboard]: Properties<{
    source: string;
    vcw?: boolean;
  }>;
  [AnalyticsEventKey.upsellModalsContextual]: Properties<{
    variant: string;
  }>;
};

export type AnalyticsEventProperty<Key extends AnalyticsEventKey> = Key extends keyof AnalyticsEventProperties ? AnalyticsEventProperties[Key] : {};
