import { createEventEmitter } from 'lib/speechify/EventEmitter';
import { replaceUrlWithoutAnyEffect } from 'utils/navigation';
import { Nullable } from 'utils/types';

export enum ContentMetaType {
  PDF = 'PDF',

  HTML = 'HTML',
  DOCX = 'DOCX',
  TXT = 'TXT',
  SCAN = 'SCAN',
  EPUB = 'EPUB',

  UNKNOWN = 'UNKNOWN'
}

export type ContentMetaTypeLowercase = Lowercase<ContentMetaType>;

export enum ContentType {
  libraryItem = 'libraryItem',
  file = 'file',
  url = 'url'
}

export abstract class BaseListenableContent {
  protected eventEmitter = createEventEmitter<{
    onTitleUpdate: {};
  }>({
    events: ['onTitleUpdate']
  });

  abstract get title(): string;
  abstract get metaType(): ContentMetaType;
  abstract get contentType(): ContentType;
  abstract get wordCount(): Nullable<number>;

  protected _titleOverride: string | null = null;
  updateTitle(title: string) {
    this._titleOverride = title;
    this.eventEmitter.emit('onTitleUpdate', { title });
  }

  isPDF = (): boolean => this.metaType === ContentMetaType.PDF;

  listenToTitleUpdate(event: 'onTitleUpdate', listener: (latestTitle: string) => void) {
    const callback = () => listener(this.title);
    this.eventEmitter.on(event, callback);
    listener(this.title);
    return () => this.eventEmitter.off(event, callback);
  }

  onItemIdReady(itemId: string): void {
    // replace client side URL with /item/{itemId}
    replaceUrlWithoutAnyEffect(`/item/${itemId}`);
  }
}
