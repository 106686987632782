import { IItemState, IRecord, ItemActionType } from 'interfaces';

import { createSlice } from '@reduxjs/toolkit';

const name = 'item';

const initialState: IItemState = {
  confirmItem: undefined,
  confirmItemAction: undefined,
  itemToRename: undefined,
  itemToMove: undefined,
  itemToDownload: undefined,
  onboardingDemoDocId: undefined,
  renameTitleOverride: ''
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setConfirmItem: (state, action: { payload: { item: IRecord; action: ItemActionType } | undefined }) => {
      if (action.payload?.item && action.payload?.action) {
        state.confirmItem = action.payload.item;
        state.confirmItemAction = action.payload.action;
      } else {
        state.confirmItem = undefined;
        state.confirmItemAction = undefined;
      }
    },

    setItemToRename: (state, action: { payload: { item: IRecord; titleOverride?: string } } | undefined) => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      state.itemToRename = action.payload?.item || undefined;
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      state.renameTitleOverride = action.payload?.titleOverride || undefined;
    },

    setItemToMove: (state, action: { payload: IRecord }) => {
      state.itemToMove = action.payload;
    },

    setItemToDownload: (state, action: { payload: IRecord }) => {
      state.itemToDownload = action.payload;
    },

    setOnboardingDemoDocId: (state, action: { payload: string | undefined }) => {
      state.onboardingDemoDocId = action.payload;
    }
  }
});

const actions = { ...generatedActions };
const selectors = {};

export { actions, selectors };

export default reducer;
