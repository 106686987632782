export enum UpgradeSource {
  contextualUpsell = 'web_app_contextual_upsell',
  contextualUpsellControl = 'web_app_contextual_upsell_control',
  ocrUpsell = 'web_app_ocr_upsell_upgrade',
  visualCta = 'web_app_audio_visual_cta_upgrade',
  voiceCloneCta = 'web_app_voice_clone_cta_upgrade',
  skipContentUpsell = 'web_app_skip_content_upsell',
  speedPickerUpsell = 'web_app_speed_picker_upsell',
  helpCenterMessageBtn = 'web_app_help_center_message_upsell',
  unlimitedSpeedPopup = 'web_app_unlimited_speed_popup',
  basicTrialNotification = 'web_app_basic_trial_notification_upgrade',
  skipHeaderFooterUpsell = 'web_app_skip_header_footer_upsell',
  voiceSelector = 'web_app_voice_selector_upgrade',
  pdfLimitPopup = 'web_app_pdf_limit_popup',
  profoile = 'web_app_profile_upgrade',
  subscriptionSettings = 'web_app_subscription_settings_upgrade',
  sidebar = 'web_app_sidebar_upgrade',
  playerSettings = 'web_app_player_settings',
  speedControls = 'web_app_speed_controls_upgrade',
  voiceControls = 'web_app_voice_controls_upgrade',
  accountPage = 'web_app_account_page_upgrade',
  askAI = 'web_app_ask_ai_upgrade',
  downloadMp3 = 'web_app_download_mp3_upgrade',
  imageOnlyPDFs = 'web_app_image_only_pdfs_upgrade',
  premiumVoices = 'web_app_premium_voices_upgrade',
  unlimitedFiles = 'web_app_unlimited_files_upgrade'
}
