import { createNonPersistentStore } from 'lib/zustand';

export enum ListeningOwnership {
  OWN,
  SHARED
}

export type ListeningModeStoreState = {
  ownership: ListeningOwnership;
};

export const useListeningModeStore = createNonPersistentStore<ListeningModeStoreState>(() => {
  return {
    ownership: ListeningOwnership.OWN
  };
});
