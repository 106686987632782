import { IUser } from 'interfaces';
import * as speechify from 'lib/speechify';
import { User } from 'lib/speechify/auth';
import { logError } from 'lib/observability';
import { RootState } from 'store';
import { createAnonymousUser, loginWithCustomToken, resetSessionCookie, setUser } from 'store/auth/actions';
import { logSegmentEvent, segmentIdentifyByUserId } from 'utils/analytics';
import { ErrorSource } from 'constants/errors';

import { createAsyncThunk } from '@reduxjs/toolkit';

// type alias incase the qr code data changes with future implementations
export type CrossLoginQrCodeData = string;

export const fetchCrossLoginQr = createAsyncThunk('auth/fetchCrossLoginQr', async (_: void, { dispatch, getState, rejectWithValue }) => {
  const {
    auth: { user: previousUser }
  } = getState() as RootState;
  let user: User | IUser;

  try {
    if (!previousUser) {
      user = await createAnonymousUser();
      dispatch(setUser(user as User));
    } else {
      user = previousUser;
    }

    let response = await fetch('/api/auth/cross-login/set-anonymous-uid', { method: 'POST', cache: 'no-store' });

    if (response.status === 401 && response.statusText === 'Unauthorized') {
      await resetSessionCookie();
      await speechify.auth.signOut();
      user = await createAnonymousUser();
      dispatch(setUser(user as User));

      response = await fetch('/api/auth/cross-login/set-anonymous-uid', { method: 'POST', cache: 'no-store' });
    }

    const { qrCodeData } = await response.json();

    return qrCodeData as CrossLoginQrCodeData;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'user' is used before being assigned.
    if (user && user.uid) {
      segmentIdentifyByUserId(user.uid);
      logSegmentEvent('web_app_cross_login', { source: 'fetchQr', mode: 'login' });
    }
  }
});

export const checkAuthStatus = createAsyncThunk('auth/checkAuthStatus', async (_: void, { getState, dispatch, rejectWithValue }) => {
  const {
    auth: { user }
  } = getState() as RootState;
  try {
    const data = await (await fetch('/api/auth/cross-login/auth-status', { method: 'GET' })).json();
    const { accessToken } = data;
    if (accessToken) {
      dispatch(loginWithCustomToken(accessToken));
    }
    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    if (user && user.uid) {
      segmentIdentifyByUserId(user.uid);
      logSegmentEvent('web_app_cross_login', { source: 'checkAuthStatus', mode: 'login' });
    }
  }
});
