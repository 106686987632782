import { IRecord, ItemType } from 'interfaces';
import getT from 'next-translate/getT';
import { REHYDRATE } from 'redux-persist';
import { actions as authActions } from 'store/auth';
import { actions as libraryActions } from 'store/library';
import { actions as toastActions } from 'store/toast';
import { descendantIds } from 'utils';
import { logSegmentEvent } from 'utils/analytics';

import { type Middleware } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '../index';

// ESLint: Don't use `{}` as a type
// eslint-disable-next-line @typescript-eslint/ban-types
const libraryMiddleware: Middleware<{}, RootState> =
  ({ dispatch: aDispatch, getState }) =>
  next =>
  async action => {
    const state = getState();
    // This is to make TS happy. As referencing it above in the generic will cause circular type dependency. Keeping it here will not
    const dispatch = aDispatch as AppDispatch;
    switch (action.type) {
      case REHYDRATE: {
        const currentUploadingId = action.payload?.library?.currentUploadingId;

        if (currentUploadingId) {
          dispatch(libraryActions.setCurrentUploadingId(null));
        }

        break;
      }

      case libraryActions.setCurrentFolderId.type: {
        dispatch(libraryActions.subscribe(action.payload));
        break;
      }

      case libraryActions.addTextDocument.fulfilled.type:
      case libraryActions.addWebLink.fulfilled.type:
      case libraryActions.restoreItem.fulfilled.type:
      case libraryActions.deleteItem.fulfilled.type:
      case libraryActions.archiveItem.fulfilled.type:
      case libraryActions.archiveItems.fulfilled.type: {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        if (state.auth.user.uid) {
          dispatch(libraryActions.fetchItemsCount());
        }
        break;
      }

      case libraryActions.archiveItem.pending.type:
      case libraryActions.deleteItem.pending.type: {
        const itemId = action.meta.arg;
        const item = state.library.items.find((item: IRecord) => item.id === itemId) || state.library.folders.find((folder: IRecord) => folder.id === itemId);

        // archive / delete folder contents
        if (item) {
          if (item.type === ItemType.Folder) {
            const childIds = descendantIds(state.library.items, item);

            childIds.forEach((childId: string) => {
              if (action.type === libraryActions.archiveItem.pending.type) {
                dispatch(libraryActions.archiveItem({ itemId: childId, noToasts: true }));
              } else if (action.type === libraryActions.deleteItem.pending.type) {
                dispatch(libraryActions.deleteItem(childId));
              }
            });
          }
        }

        break;
      }

      case libraryActions.addWebLink.rejected.type: {
        const locale = state.app.locale;
        const t = await getT(locale, 'common');

        dispatch(
          toastActions.add({
            title: t('Error!'),
            description: t('Failed to add web link'),
            type: 'error'
          })
        );

        break;
      }

      case libraryActions.setName.fulfilled.type: {
        const locale = state.app.locale;
        const t = await getT(locale, 'common');

        dispatch(
          toastActions.add({
            title: t('Success'),
            description: t('Item renamed successfully'),
            type: 'success'
          })
        );

        break;
      }

      case libraryActions.itemCompleted.type: {
        const { itemId, type } = action.payload;
        logSegmentEvent('web_app_document_completed', { itemId, type });
        break;
      }

      case authActions.logout.pending.type: {
        dispatch(libraryActions.reset());
        libraryActions.unsubscribe();
        break;
      }
    }

    next(action);
  };

export default libraryMiddleware;
