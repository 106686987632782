import { IntegrationFile, IntegrationAuthResponse, IntegrationService, SORT_BY_FIELD, SORT_ORDER } from 'interfaces/integrations';
import * as integrationActions from './actions';
import { isThisFulfilledAction, isThisPendingAction, isThisRejectedAction } from 'utils/redux';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { CanvasIntegrationCredentials, IntegrationState } from './types';

const name = 'integration';
const initialState: IntegrationState = {
  auths: {},
  isLoading: false,
  importedItems: {},
  suggestedItems: [],
  showTooltip: {}
};

const unauthorizedIntegration: IntegrationAuthResponse = { authorized: false };

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<{ sortBy: SORT_BY_FIELD; sortOrder: SORT_ORDER }>) => {
      const { sortBy, sortOrder } = action.payload;
      return { ...state, sortBy, sortOrder };
    },
    setFilter: (state, { payload }: PayloadAction<string | undefined>) => {
      return { ...state, filter: payload };
    },
    setFileImported: ({ importedItems, ...state }, action: PayloadAction<{ service: IntegrationService; file: IntegrationFile; itemId: string }>) => {
      const { file, service, itemId } = action.payload;
      const serviceImportedItems = {
        ...(importedItems[service] || {}),
        [`${file.id}_${file.sizeBytes}`]: itemId
      };

      return {
        ...state,
        importedItems: {
          ...importedItems,
          [service]: serviceImportedItems
        }
      };
    },
    showTooltip: (state, { payload }: PayloadAction<IntegrationService>) => {
      return { ...state, showTooltip: { ...state.showTooltip, [payload]: true } };
    },
    hideTooltip: (state, { payload }: PayloadAction<IntegrationService>) => {
      return { ...state, showTooltip: { ...state.showTooltip, [payload]: false } };
    },
    setCanvasData: (state, { payload }: PayloadAction<CanvasIntegrationCredentials | null>) => {
      return { ...state, [IntegrationService.CANVAS]: payload };
    },
    disableSuggestions: (state, { payload }: PayloadAction<IntegrationService>) => {
      return {
        ...state,
        suggestionDisabled: {
          ...state.suggestionDisabled,
          [payload]: true
        }
      };
    },
    enableSuggestions: (state, { payload }: PayloadAction<IntegrationService>) => {
      return {
        ...state,
        suggestionDisabled: {
          ...state.suggestionDisabled,
          [payload]: false
        }
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(integrationActions.fetchAuths.fulfilled, (state, action) => {
      state.auths = action.payload;
    });

    builder.addCase(integrationActions.fetchSuggestions.fulfilled, (state, action) => {
      state.suggestedItems = action.payload;
    });

    builder.addCase(integrationActions.authorizeService.fulfilled, (state, action) => {
      state.auths[action.meta.arg.service] = action.payload;

      if (action.payload.authorized) {
        state.showTooltip = { ...state.showTooltip, [action.meta.arg.service]: true };
      }
    });

    builder.addCase(integrationActions.logoutFromService.fulfilled, (state, action) => {
      if (action.payload.logout) {
        state.auths[action.meta.arg] = unauthorizedIntegration;
      }
    });

    builder
      .addMatcher(isThisPendingAction(name), state => {
        state.isLoading = true;
      })
      .addMatcher(isThisRejectedAction(name), state => {
        state.isLoading = false;
      })
      .addMatcher(isThisFulfilledAction(name), state => {
        state.isLoading = false;
      });
  }
});

const getAuthByService = (service: IntegrationService) => (state: RootState) => state.integration.auths[service] ?? unauthorizedIntegration;

const getSortBy = (state: RootState) => state.integration.sortBy ?? SORT_BY_FIELD.CREATED_TIME;

const getSortOrder = (state: RootState) => state.integration.sortOrder ?? SORT_ORDER.DESC;

const getImportedItemId = (service: IntegrationService, file: IntegrationFile) => (state: RootState) => {
  const key = `${file.id}_${file.sizeBytes}`;
  const importedItems = state.integration.importedItems || {};
  return importedItems[service]?.[key] ?? null;
};

const isSuggestionEnabled = (service: IntegrationService) => (state: RootState) => !state.integration.suggestionDisabled?.[service];

const actions = {
  ...generatedActions,
  ...integrationActions
};

const selectors = {
  getAuthByService,
  getSortBy,
  getSortOrder,
  getImportedItemId,
  isSuggestionEnabled
};

export { actions, selectors };

export default reducer;
