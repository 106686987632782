import { BookReadingInfo, ClassicReadingInfo } from 'components/experience/readers/newsdk/ReadingInfo';
import { createBookReaderFromLibraryItem, createClassicReaderFromLibraryItem } from 'components/experience/readers/newsdk/setup';
import { decode, encodeGenerator } from 'gpt-tokenizer';
import { IRecord } from 'interfaces';
import { promisify } from 'lib/speechify/adaptors/promisify';

import { StandardBlock, StandardBlocksFetcher } from '@speechifyinc/multiplatform-sdk';

export type SummaryLength = 'short' | 'medium' | 'long';
export type SummaryMode = 'paragraph' | 'keypoints';

type Paragraph = StandardBlock.Paragraph;
type Footer = StandardBlock.Footer;
type Footnote = StandardBlock.Footnote;
type Header = StandardBlock.Header;
type Heading = StandardBlock.Heading;
type HaveTextContent = Paragraph | Footer | Footnote | Header | Heading;

function ensureTokenLength(content: string, length: number = 12000) {
  const tokens = [];
  for (const tokenChunk of encodeGenerator(content)) {
    tokens.push(...tokenChunk);

    if (tokens.length > length) {
      break;
    }
  }

  return decode(tokens);
}

const readerCache = new Map<string, Promise<BookReadingInfo> | Promise<ClassicReadingInfo>>();

const getReaderFromItem = (item: IRecord) => {
  const cachedResult = readerCache.get(item.id);
  if (cachedResult) return cachedResult;

  const reader = item.recordType === 'PDF' ? createBookReaderFromLibraryItem(item) : createClassicReaderFromLibraryItem(item);

  readerCache.set(item.id, reader);
  return reader;
};

const getItemContent = async (item: IRecord) => {
  const reader = await getReaderFromItem(item);

  // 120 give around maximum text we need for 16k token limit of chatgpt
  const sbf = new StandardBlocksFetcher(reader.standardView, 120 as const);
  const blocks = await promisify(sbf.getBlocksForSuggestedSizeAroundCursor.bind(sbf))(reader.standardView.start);
  const text = blocks.blocks
    .map(block =>
      block instanceof StandardBlock.List ? block.items.map(it => it.text.textRepresentation) : (block as HaveTextContent).text.textRepresentation
    )
    .join('. ');

  return text;
};

const getItemContentBetweenIndexes = async (item: IRecord, pageIndexes: number[]) => {
  if (item.recordType !== 'PDF') throw new Error('Cannot fetch content between pages for non PDF items.');
  const { bookView, standardView } = (await getReaderFromItem(item)) as BookReadingInfo;

  const pages = await promisify(bookView.getPages.bind(bookView))(pageIndexes);

  const blocks = await Promise.all(
    pages.map(p => {
      return promisify(standardView.getBlocksBetweenCursors.bind(standardView))(p.start, p.end);
    })
  );

  let content = '';

  for (const block of blocks) {
    const text = block.blocks
      .map(block =>
        block instanceof StandardBlock.List ? block.items.map(it => it.text.textRepresentation) : (block as HaveTextContent).text.textRepresentation
      )
      .join('. ');

    content = content.concat(text);
  }

  return content;
};

const wordLimits = {
  short: 100,
  medium: 150,
  long: 250
};

function getChatPromptText() {
  return `
      You are a highly skilled AI trained in language comprehension. I would like you to read the following text
      and answer any follow up questions that user will have. Prioritize brevity and correctness. 
      Answer all the users questions correctly and use your knowledge to expand on any details. 
      Requirements: - 
      - Answers MUST BE provided in the language the original text was written.
      - ALWAYS USE MARKDOWN SYNTAX IN RESPONSES
  `;
}

function getSummaryPromptText(length: SummaryLength, mode: SummaryMode) {
  const currentWordLimit = wordLimits[length];

  const prompt = `
  Summarize the given text in an educational tone, focusing on the main arguments and key findings. 
  Prioritize brevity and provide a concise summary within a strict ${currentWordLimit} word limit as ${mode === 'paragraph' ? 'paragraphs' : 'bullet points list'} suitable for a general audience. 

  Requirements: 
    - The summary MUST BE provided in the language the original text was written, 
    - ALWAYS USE MARKDOWN SYNTAX TO RESPOND.
    - ${mode === 'paragraph' ? 'Use PARAGRAPHS to respond.' : 'Use BULLET POINTS to respond.'}
  `;

  return prompt;
}

export { ensureTokenLength, getChatPromptText, getItemContent, getItemContentBetweenIndexes, getSummaryPromptText };
