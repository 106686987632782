import { createNonPersistentStore } from 'lib/zustand';

export type RenderedContentStoreState = {
  renderedContentMap: Map<string, Element | null>;
  renderedBlockMap: Map<number, Element | null>;
};

export const useRenderedContentStore = createNonPersistentStore<RenderedContentStoreState>(() => {
  return {
    renderedContentMap: new Map(),
    renderedBlockMap: new Map()
  };
});

const getRenderedContent = (contentId: string) => {
  const state = useRenderedContentStore.getState() ?? { renderedContentMap: new Map() };
  return state.renderedContentMap.get(contentId);
};

const getRenderedBlock = (blockIndex: number) => {
  const state = useRenderedContentStore.getState() ?? { renderedBlockMap: new Map() };
  return state.renderedBlockMap.get(blockIndex);
};

const updateRenderedContent = (contentId: string, element: Element | null) => {
  const state = useRenderedContentStore.getState() ?? { renderedContentMap: new Map() };
  state.renderedContentMap.set(contentId, element);
  useRenderedContentStore.setState(state);
};

const updateRenderedBlock = (blockIndex: number, element: Element | null) => {
  const state = useRenderedContentStore.getState() ?? { renderedContentMap: new Map() };
  state.renderedBlockMap.set(blockIndex, element);
  useRenderedContentStore.setState(state);
};

export const renderedContentStoreSelectors = {
  getRenderedContent,
  getRenderedBlock
};

export const renderedContentStoreActions = {
  updateRenderedContent,
  updateRenderedBlock
};
