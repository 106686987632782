import { ErrorSource } from 'constants/errors';
import { instrumentAction, logError } from 'lib/observability';
import { CDN_PSPDFKIT_BASE_URL, PSPDFKIT_KEY } from 'lib/pdf/constants';
import { type Instance as PSPDFKitInstance, type OutlineElement } from 'pspdfkit';
import { importPSPDFKIT } from 'utils/pspdfkit';

import {
  AbstractPDFAdapterFactory,
  AbstractPDFDocumentAdapter,
  AbstractPDFPageAdapter,
  PDFDocumentAdapter,
  PDFDocumentMetadata,
  PDFOutline,
  PDFPageAdapter,
  PDFPageImageOptions,
  PDFPageMetadata,
  PDFPageTextContentItem
} from '@speechifyinc/multiplatform-sdk/api/adapters/pdf';
import {
  BinaryContentReadableRandomly,
  BinaryContentWithMimeTypeFromNativeReadableInChunks,
  binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob,
  Result,
  SDKError
} from '@speechifyinc/multiplatform-sdk/api/util';
import { BoundingBoxUtils, Viewport } from '@speechifyinc/multiplatform-sdk/api/util/images';

import { Callback } from './lib/typeAliases';

export class WebPDFAdapterFactory extends AbstractPDFAdapterFactory {
  async getPDFDocumentAdapter(file: BinaryContentReadableRandomly, callback: Callback<PDFDocumentAdapter>) {
    try {
      const data = await instrumentAction(() => file.blob.arrayBuffer(), 'file.blob.arrayBuffer()');
      const PSPDFKit = await instrumentAction(importPSPDFKIT, 'import_pspdfkit');

      const instance = await PSPDFKit.load({
        baseUrl: CDN_PSPDFKIT_BASE_URL,
        document: data,
        headless: true,
        licenseKey: PSPDFKIT_KEY,
        theme: PSPDFKit.Theme.AUTO,
        // ESLint: Use "@ts-expect-error" instead of "@ts-ignore", as "@ts-ignore" will do nothing if the following line is error-free
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS(2345): Argument of type '{ container: null; }' is not assignable to parameter of type 'HTMLElement'
        container: null
      });

      callback(new Result.Success(new WebPDFDocumentAdapter(instance)));
    } catch (e) {
      logError(e as Error, ErrorSource.READING_EXPERIENCE);
      callback(new Result.Failure(new SDKError.OtherException(e as Error)));
    }
  }
}

class WebPDFDocumentAdapter extends AbstractPDFDocumentAdapter {
  private pages: PDFPageAdapter[] = [];

  constructor(private readonly instance: $TSFixMe) {
    super();
  }

  async getPages(pageIndexes: number[], callback: Callback<PDFPageAdapter[]>) {
    const pages = pageIndexes.map(index => {
      if (this.pages[index]) {
        return this.pages[index];
      }

      const adapter = new WebPDFPageAdapter(this.instance, index);
      this.pages[index] = adapter;

      return adapter;
    });

    callback(new Result.Success(pages));
  }

  getMetadata(): PDFDocumentMetadata {
    return new PDFDocumentMetadata(this.instance.totalPageCount);
  }

  override get title(): string {
    return 'New Book';
  }

  override getThumbnail = async (callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>): Promise<void> => {
    callback(new Result.Success(await getPageThumbnail(this.instance, 0)));
  };

  async getOutline(callback: (p0: Result<PDFOutline>) => void): Promise<void> {
    callback(new Result.Success(await getPageOutline(this.instance)));
  }

  destroy() {
    const unload = async () => {
      const PSPDFKit = await importPSPDFKIT();
      PSPDFKit.unload(this.instance);
    };

    unload();
  }
}

class WebPDFPageAdapter extends AbstractPDFPageAdapter {
  constructor(
    private readonly instance: $TSFixMe,
    private readonly index: number
  ) {
    super();
  }

  get pageIndex() {
    return this.index;
  }

  async getTextContent(callback: Callback<PDFPageTextContentItem[]>) {
    const textLines = await this.instance.textLinesForPageIndex(this.index);
    callback(
      new Result.Success(
        textLines
          .map((line: $TSFixMe) => {
            const { height, left, top, width } = line.boundingBox;
            // account for top & left SDK bug
            return new PDFPageTextContentItem(line.contents, BoundingBoxUtils.fromDimensionsAndCoordinates(width, height, left, top), null);
          })
          .toArray()
      )
    );
  }

  getMetadata() {
    const pageInfo = this.instance.pageInfoForIndex(this.index);
    return new PDFPageMetadata(new Viewport(pageInfo?.width || 0, pageInfo?.height || 0));
  }

  destroy() {}

  async getImage(options: PDFPageImageOptions, callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>) {
    callback(new Result.Success(await getPageThumbnail(this.instance, this.pageIndex)));
  }
}

const getPageThumbnail = async (
  instance: $TSFixMe,
  pageIndex: number
): Promise<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> => {
  const blobUrl: string = await instance.renderPageAsImageURL({ width: 1024 }, pageIndex);
  const binaryContent = binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob(await urlToBlob(blobUrl));
  return binaryContent;
};

const getPageOutline = async (instance: PSPDFKitInstance): Promise<PDFOutline> => {
  const outline = await instance.getDocumentOutline();
  const entries = convertOutlineToEntries(outline.toArray());
  return new PDFOutline(entries);
};

function convertOutlineToEntries(outline: OutlineElement[], level: number = 0): PDFOutline.Entry[] {
  return outline.flatMap(element => {
    const entry = new PDFOutline.Entry(element.title, level, new PDFOutline.Entry.Attributes(element.action?.pageIndex ?? 0));

    const children = element.children ? convertOutlineToEntries(element.children.toArray(), level + 1) : [];
    return [entry, ...children];
  });
}

const urlToBlob = async (blobURL: string): Promise<Blob> => {
  try {
    // Fetch the Blob data from the URL
    const response = await fetch(blobURL);

    if (!response.ok) {
      throw new Error('Failed to fetch Blob data');
    }

    // Get the Blob data
    const blobData = await response.blob();

    return blobData;
  } catch (error) {
    logError(error as Error, ErrorSource.READING_EXPERIENCE, {
      context: {
        url: blobURL
      }
    });
    return new Blob([], { type: '' });
  }
};
