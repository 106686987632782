import { SummaryDialogState } from 'components/sidebar/controls/AskAi/Chat';
import { v4 } from 'uuid';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { chat, summarize } from './actions';
import * as selectors from './selectors';

type Role = 'assistant' | 'user';
type Presentation = 'summary_card' | 'chat_bubble';

export type MessageState = {
  id: string;
  content: string;
  role: Role;
  presentation: Presentation;
  loading: boolean;
  options?: Omit<SummaryDialogState, 'open'>;
};

type MessageMap = Record<string, MessageState[] | undefined>;

type AskAiSlice = {
  messages: MessageMap;
  autoplay: boolean;
};

const initialState: AskAiSlice = {
  messages: {},
  autoplay: true
};

export const askAiSlice = createSlice({
  name: 'askAiV2',
  initialState,
  reducers: {
    reset: () => initialState,

    addMessage: (state, action: PayloadAction<{ itemId: string; id: string; role: Role; content: string; presentation: Presentation }>) => {
      const { id, itemId, role = 'user', content, presentation } = action.payload;
      const currentMessages = state.messages[itemId] ?? [];
      state.messages[itemId] = [{ role, content, id, loading: false, presentation }, ...currentMessages];
    },

    toggleAutoplay: state => {
      state.autoplay = !state.autoplay;
    },
    clearChat: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.messages[id] = [];
    }
  },
  extraReducers(builder) {
    return builder
      .addCase(summarize.pending, (state, action) => {
        const { id, item, allPages, length, mode, pageIndexes } = action.meta.arg;
        if (!state.messages[item.id] || state.messages[item.id]?.length === 0) state.messages[item.id] = [];

        const messages = state.messages[item.id]!;
        const ref = messages.find(msg => msg.id === id);
        if (ref) {
          ref.loading = true;
        } else {
          messages.push({
            content: '',
            loading: true,
            id,
            presentation: 'summary_card',
            role: 'assistant',
            options: {
              allPages,
              length,
              mode,
              pageIndexes
            }
          });
        }
      })
      .addCase(
        summarize.fulfilled,
        (
          state,
          {
            payload: { answer, item },
            meta: {
              arg: { id }
            }
          }
        ) => {
          const ref = state.messages[item.id]!.find(msg => msg.id === id);
          if (ref) {
            ref.content = answer;
            ref.loading = false;
          }
        }
      )
      .addCase(
        summarize.rejected,
        (
          state,
          {
            meta: {
              arg: { item, id }
            }
          }
        ) => {
          const messages = state.messages[item.id];
          messages?.splice(
            messages.findIndex(m => m.id === id),
            1
          );
        }
      )
      .addCase(chat.pending, (state, action) => {
        const { id, item, content } = action.meta.arg;
        if (!state.messages[item.id] || state.messages[item.id]?.length === 0) state.messages[item.id] = [];
        // Push message typed by user into the messages state
        state.messages[item.id]?.push({
          content,
          loading: false,
          id: v4(),
          presentation: 'chat_bubble',
          role: 'user'
        });

        // Push placeholder to store message that will be returned by the server
        state.messages[item.id]?.push({
          content: '',
          loading: true,
          id,
          presentation: 'chat_bubble',
          role: 'assistant'
        });
      })
      .addCase(
        chat.fulfilled,
        (
          state,
          {
            payload: { answer, item },
            meta: {
              arg: { id }
            }
          }
        ) => {
          const ref = state.messages[item.id]!.find(msg => msg.id === id);
          if (ref) {
            ref.content = answer;
            ref.loading = false;
          }
        }
      )
      .addCase(
        chat.rejected,
        (
          state,
          {
            meta: {
              arg: { item, id }
            }
          }
        ) => {
          const messages = state.messages[item.id];
          messages?.splice(
            messages.findIndex(m => m.id === id),
            1
          );
        }
      );
  }
});

const actions = {
  ...askAiSlice.actions,
  summarize,
  chat
};

export { actions, selectors };

export default askAiSlice.reducer;
