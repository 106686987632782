import { ExtensionSettings, IUser, UsageStats } from 'interfaces';
import { INITIAL_PLAYBACK_RELATIVE_SPEED } from 'modules/speed/utils/constants';
import { RootState } from 'store';
import {
  login,
  loginAnonymously,
  loginWithApple,
  loginWithCustomToken,
  loginWithFacebook,
  loginWithGoogle,
  logout,
  sendPasswordResetEmail,
  setPlaybackSpeed,
  setUser,
  setVoice,
  signUp,
  signUpWithApple,
  signUpWithFacebook,
  signUpWithGoogle,
  updateProfile
} from 'store/auth/actions';
import { isThisFulfilledAction, isThisPendingAction, isThisRejectedAction } from 'utils/redux';
import { combinePlatformUsageStats } from 'utils/stats';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { Entitlements, Subscription } from '@speechifyinc/multiplatform-sdk';

const name = 'auth';
type AuthState = {
  isLoading: boolean;
  user: IUser | null;
};
const initialState: AuthState = {
  isLoading: false,
  user: null
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setGoogleDocsRedirect: (state, action: { payload: boolean }) => {
      if (state.user) {
        state.user.redirectGoogleDoc = action.payload;
      }
    },
    setClickedStartListening: state => {
      if (state.user) {
        state.user.clickedStartListening = true;
      }
    },
    setExtensionPinned: (state, action: { payload: boolean }) => {
      if (state.user) {
        state.user.extensionPinned = action.payload;
      }
    },
    setFileUploaded: (state, action: { payload: boolean }) => {
      if (state.user) {
        state.user.fileUploaded = action.payload;
      }
    },
    clearBillingDashboardUrl: state => {
      if (state.user) {
        state.user.billingDashboardUrl = null;
      }
    },
    setBillingDashboardUrl: (state, action: { payload: string }) => {
      if (state.user) {
        state.user.billingDashboardUrl = action.payload;
      }
    },
    setEntitlements: (state, action: { payload: Entitlements }) => {
      if (state.user) {
        state.user.entitlements = action.payload;
      }
    },
    setExtensionInstalled: (state, action: { payload: boolean }) => {
      if (state.user) {
        if (action.payload) {
          state.user.extensionInstalled = action.payload;
        }
      }
    },
    setExtensionSettings: (state, action: { payload: ExtensionSettings }) => {
      if (state.user) {
        state.user.extensionSettings = action.payload;
      }
    },
    setMobileAppInstalled: (state, action: { payload: boolean }) => {
      if (state.user) {
        if (action.payload) {
          // only set if true. we don't want to overwrite the value if it's false
          state.user.mobileAppInstalled = action.payload;
        }
      }
    },
    setSubscription: (state, action: { payload: Subscription }) => {
      if (state.user) {
        state.user.subscription = action.payload;
      }
    },
    setUsageStats: (state, action: { payload: UsageStats }) => {
      if (state.user) {
        state.user.usage = action.payload;
      }
    },
    setWordsLeft: (state, action: { payload: number }) => {
      if (state.user) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        state.user.entitlements.hdWordsLeft = action.payload;
      }
    },
    setHasSetDailyListeningGoal: (state, action) => {
      if (state.user) {
        state.user.hasSetDailyListeningGoal = action.payload;
      }
    },
    setAutoSpeedUp: (state, action: { payload: boolean }) => {
      if (state.user) {
        // The use of spread here is in case extension settings is undefined
        // @ts-expect-error TS(2322): Type '{ autoSpeedUp: boolean; playbackSpeed?: numb... Remove this comment to see the full error message
        state.user.extensionSettings = {
          ...state.user.extensionSettings,
          autoSpeedUp: action.payload
        };
      }
    },
    setFirstPdfDocument: (state, action) => {
      if (state.user) {
        state.user.firstPdfDocument = action.payload;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(logout.fulfilled, state => {
      state.user = null;
    });

    builder.addCase(setUser.fulfilled, (state, action) => {
      // @ts-expect-error TS(2322): Type '{ redirectGoogleDoc?: boolean | null | undef... Remove this comment to see the full error message
      state.user = {
        ...state.user,
        ...action.payload
      };
    });

    builder.addCase(setPlaybackSpeed.fulfilled, (state, action) => {
      if (state.user) {
        // @ts-expect-error TS(2322): Type '{ playbackSpeed: number; autoSpeedUp?: boole... Remove this comment to see the full error message
        state.user.extensionSettings = {
          ...state.user.extensionSettings,
          playbackSpeed: action.payload
        };
      }
    });

    builder.addCase(setVoice.fulfilled, (state, action) => {
      if (state.user) {
        // @ts-expect-error TS(2322): Type '{ voice: { displayName: string; engine: stri... Remove this comment to see the full error message
        state.user.extensionSettings = {
          ...state.user.extensionSettings,
          voice: action.payload
        };
      }
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      if (state.user) {
        state.user.displayName = action.payload.displayName;
      }
    });

    builder
      .addMatcher(isThisPendingAction(name), state => {
        state.isLoading = true;
      })
      .addMatcher(isThisRejectedAction(name), state => {
        state.isLoading = false;
      })
      .addMatcher(isThisFulfilledAction(name), state => {
        state.isLoading = false;
      });
  }
});

export const getPlaybackSpeed = createSelector(
  (state: RootState) => state.auth.user?.extensionSettings,
  (extensionSettings: ExtensionSettings | undefined) => extensionSettings?.playbackSpeed || INITIAL_PLAYBACK_RELATIVE_SPEED
);

export const getVoice = createSelector(
  (state: RootState) => state.auth.user?.extensionSettings,
  (extensionSettings: ExtensionSettings | undefined) => extensionSettings?.voice || null
);

export const getVoiceName = createSelector(
  (state: RootState) => state.auth.user?.extensionSettings,
  (extensionSettings: ExtensionSettings | undefined) => extensionSettings?.voice?.displayName || null
);

export const getCombinedUsageStats = createSelector(
  (state: RootState) => state.auth.user,
  (user: IUser | null) => {
    const { android, iOS, web, ext } = user?.usage?.stats || {};
    return {
      stats: combinePlatformUsageStats(android, iOS, web, ext),
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      expires: user.usage?.expires
    };
  }
);

export const getAutoSpeedUp = createSelector(
  (state: RootState) => state.auth.user?.extensionSettings,
  (extensionSettings: ExtensionSettings | undefined) => extensionSettings?.autoSpeedUp || false
);

const getSelectedPersonalVoice = (state: RootState) => state.personalVoices.selectedPersonalVoice;
const getUserExtensionVoice = (state: RootState) => state.auth.user?.extensionSettings?.voice;

export const getInitialVoice = createSelector(
  [getSelectedPersonalVoice, getUserExtensionVoice],
  (selectedPersonalVoice, userExtensionVoice) => selectedPersonalVoice || userExtensionVoice
);

const actions = {
  ...generatedActions,
  login,
  signUp,
  loginAnonymously,
  loginWithCustomToken,
  loginWithApple,
  loginWithFacebook,
  loginWithGoogle,
  logout,
  sendPasswordResetEmail,
  setPlaybackSpeed,
  setUser,
  setVoice,
  signUpWithApple,
  signUpWithFacebook,
  signUpWithGoogle,
  updateProfile
};

const selectors = { getPlaybackSpeed, getVoice, getVoiceName, getCombinedUsageStats, getAutoSpeedUp };

export { actions, generatedActions, selectors };

export default reducer;
