import {
  AudioController as AudioControllerSDK,
  AudioControllerEvent,
  ContentBundle,
  ContentBundlerOptions,
  ContentOverlayProvider,
  CursorQuery,
  EmbeddedReadingBundle,
  PlaybackControls as PlaybackControlsSDK,
  PlaybackTime as PlaybackTimeSDK,
  ReadingBundle,
  VoiceSpecOfAvailableVoice
} from '@speechifyinc/multiplatform-sdk';
import { CurrentWordAndSentenceOverlayHelper, WordsListenedHelper } from '@speechifyinc/multiplatform-sdk/helpers/features';
import { Nullable } from '@speechifyinc/multiplatform-sdk/multiplatform-sdk-multiplatform-sdk';

import { ReaderType, ThumbnailInfo } from './types';

export type ReadingInfoSkipSettings = {
  headers: boolean;
  footers: boolean;
  footnotes: boolean;
  citations: boolean;
  urls: boolean;
  squareBrackets: boolean;
  braces: boolean;
  roundBrackets: boolean;
};

type PlaybackTimeNotReady = {
  isReady: false;
};

type PlaybackTimeReady = {
  isReady: true;
  currentTimeSeconds: number;
  totalTimeSeconds: number;
};

export type PlaybackTime = PlaybackTimeNotReady | PlaybackTimeReady;

export class PlaybackControls {
  constructor(private sdkPlaybackControls: PlaybackControlsSDK) {}

  get cursor() {
    return this.sdkPlaybackControls.state.latestPlaybackCursor;
  }

  get playPauseButton() {
    return this.sdkPlaybackControls.state.playPauseButton;
  }

  get progressFraction() {
    return this.sdkPlaybackControls.state.latestPlaybackProgressFraction;
  }

  get playbackTime(): PlaybackTime {
    const state = this.sdkPlaybackControls.state.playbackTime;
    if (state instanceof PlaybackTimeSDK.Ready) {
      return {
        isReady: true,
        currentTimeSeconds: state.currentTimeSeconds,
        totalTimeSeconds: state.totalTimeSeconds
      };
    }
    return {
      isReady: false
    };
  }

  get wordsPerMinute() {
    return this.sdkPlaybackControls.state.wordsPerMinute;
  }

  createCurrentWordAndSentenceOverlayHelper<ContentRef>(overlayProvider: ContentOverlayProvider<ContentRef>) {
    return new CurrentWordAndSentenceOverlayHelper<ContentRef>(this.sdkPlaybackControls, overlayProvider);
  }

  pause() {
    this.sdkPlaybackControls.pause();
  }

  pressPlayPause() {
    this.sdkPlaybackControls.pressPlayPause();
  }

  setSpeed(speed: number) {
    this.sdkPlaybackControls.setSpeed(speed);
  }

  setVoice(voice: VoiceSpecOfAvailableVoice) {
    this.sdkPlaybackControls.setVoice(voice);
  }

  skipBackwards() {
    this.sdkPlaybackControls.skipBackwards();
  }

  skipForwards() {
    this.sdkPlaybackControls.skipForwards();
  }

  addListener(callback: (state: PlaybackControls) => void): () => void {
    return this.sdkPlaybackControls.addListener(() => callback(this));
  }

  destroy() {
    this.sdkPlaybackControls.destroy();
  }
}

class AudioController {
  constructor(private sdkAudioController: AudioControllerSDK) {}

  createWordsListenedHelper() {
    return new WordsListenedHelper(this.sdkAudioController);
  }

  addEventListener(callback: (event: AudioControllerEvent) => void): () => void {
    return this.sdkAudioController.addEventListener(callback);
  }

  play(query: CursorQuery): void {
    return this.sdkAudioController.play(query);
  }

  pause(): void {
    this.sdkAudioController.pause();
  }

  resume(): void {
    this.sdkAudioController.resume();
  }

  destroy() {
    this.sdkAudioController.destroy();
  }
}

export class BaseReadingInfo {
  public kind: ReaderType = 'classic';

  public availableVoices: VoiceSpecOfAvailableVoice[];

  private controlsCache: PlaybackControls | null = null;
  private controlsCacheValueFromSDK: PlaybackControlsSDK | null = null;

  private audioControllerCache: AudioController | null = null;
  private audioControllerCacheValueFromSDK: AudioControllerSDK | null = null;

  constructor(
    protected bundle: ReadingBundle | EmbeddedReadingBundle,
    public initialVoice: VoiceSpecOfAvailableVoice,
    public contentBundlerOptions: ContentBundlerOptions,
    public thumbnail: ThumbnailInfo
  ) {
    this.availableVoices = this.bundle.listeningBundle.voices.map(v => v.voice);
  }

  get voiceOfCurrentUtterance(): VoiceSpecOfAvailableVoice {
    return this.bundle.playbackControls.state.voiceOfCurrentUtterance || this.initialVoice;
  }

  get voiceOfPreferenceOverride() {
    return this.bundle.playbackControls.state.voiceOfPreferenceOverride;
  }

  get speechView() {
    return this.bundle.listeningBundle.contentBundle.speechView;
  }

  get playbackControls() {
    if (!this.controlsCache || this.controlsCacheValueFromSDK !== this.bundle.playbackControls) {
      this.controlsCache?.destroy();

      this.controlsCache = new PlaybackControls(this.bundle.playbackControls);
      this.controlsCacheValueFromSDK = this.bundle.playbackControls;
    }

    return this.controlsCache;
  }

  get audioController() {
    if (!this.audioControllerCache || this.audioControllerCacheValueFromSDK !== this.bundle.listeningBundle.audioController) {
      this.audioControllerCache?.destroy();

      this.audioControllerCache = new AudioController(this.bundle.listeningBundle.audioController);
      this.audioControllerCacheValueFromSDK = this.bundle.listeningBundle.audioController;
    }

    return this.audioControllerCache;
  }

  get standardView() {
    return this.bundle.listeningBundle.contentBundle.standardView;
  }

  reportUIReadyToListen() {
    if (this.kind !== 'embedded') {
      (this.bundle as ReadingBundle).reportUIReadyToListen();
    }
  }

  updateSkipSettings(skipSettings: ReadingInfoSkipSettings): void {
    this.contentBundlerOptions.shouldSkipHeaders = skipSettings.headers;
    this.contentBundlerOptions.shouldSkipFooters = skipSettings.footers;
    this.contentBundlerOptions.shouldSkipFootnotes = skipSettings.footnotes;

    this.contentBundlerOptions.preSpeechTransformOptions.shouldSkipCitations = skipSettings.citations;
    this.contentBundlerOptions.preSpeechTransformOptions.shouldSkipUrls = skipSettings.urls;
    this.contentBundlerOptions.preSpeechTransformOptions.shouldSkipBrackets = skipSettings.squareBrackets;
    this.contentBundlerOptions.preSpeechTransformOptions.shouldSkipBraces = skipSettings.braces;
    this.contentBundlerOptions.preSpeechTransformOptions.shouldSkipParentheses = skipSettings.roundBrackets;

    /* reset the voice to reset the SDK's utterance cache */
    this.bundle.listeningBundle.audioController.setVoice(this.voiceOfCurrentUtterance);
  }

  subscribeToVoiceOfCurrentUtterance(callback: (voice: Nullable<VoiceSpecOfAvailableVoice>) => void): () => void {
    return this.bundle.playbackControls.subscribeToVoiceOfCurrentUtterance(callback);
  }

  subscribeToVoiceOfPreferenceOverride(callback: (voice: Nullable<VoiceSpecOfAvailableVoice>) => void): () => void {
    return this.bundle.playbackControls.subscribeToVoiceOfPreferenceOverride(callback);
  }

  destroy() {
    this.controlsCache?.destroy();
    this.audioControllerCache?.destroy();
    this.bundle.destroy();
  }
}

export class ClassicReadingInfo extends BaseReadingInfo {
  public kind = 'classic' as const;

  constructor(bundle: ReadingBundle, initialVoice: VoiceSpecOfAvailableVoice, contentBundlerOptions: ContentBundlerOptions, thumbnail: ThumbnailInfo) {
    super(bundle, initialVoice, contentBundlerOptions, thumbnail);
  }
}

export class EmbeddedReadingInfo extends BaseReadingInfo {
  public kind = 'embedded' as const;

  constructor(
    bundle: ReadingBundle | EmbeddedReadingBundle,
    initialVoice: VoiceSpecOfAvailableVoice,
    contentBundlerOptions: ContentBundlerOptions,
    thumbnail: ThumbnailInfo
  ) {
    super(bundle, initialVoice, contentBundlerOptions, thumbnail);
  }
}

export class BookReadingInfo extends BaseReadingInfo {
  kind = 'book' as const;

  constructor(
    bundle: ReadingBundle,
    initialVoice: VoiceSpecOfAvailableVoice,
    contentBundlerOptions: ContentBundlerOptions,
    thumbnail: ThumbnailInfo,
    public file?: File | undefined,
    public url?: string | undefined
  ) {
    super(bundle, initialVoice, contentBundlerOptions, thumbnail);
  }

  get bookView() {
    return (this.bundle.listeningBundle.contentBundle as ContentBundle.BookBundle)?.bookView;
  }
}

export type ReadingInfo = ClassicReadingInfo | BookReadingInfo | EmbeddedReadingInfo;

export const isBook = (info: ReadingInfo): info is BookReadingInfo => info.kind === 'book';

export const isClassic = (info: ReadingInfo): info is ClassicReadingInfo => info.kind === 'classic';
