import React, { ChangeEvent, Fragment, useContext, useMemo } from 'react';

import Diamond from 'assets/icons/diamond';
import useMediaQuery from 'hooks/useMediaQuery';
import { useTranslation } from 'hooks/useTypedTranslation';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

export type SkipSettings = {
  braces: boolean;
  citations: boolean;
  footers: boolean;
  footnotes: boolean;
  headers: boolean;
  roundBrackets: boolean;
  squareBrackets: boolean;
  urls: boolean;
};

export const defaultSkipSettings: SkipSettings = {
  braces: false,
  citations: false,
  footers: true,
  footnotes: true,
  headers: true,
  roundBrackets: false,
  squareBrackets: false,
  urls: false
};

export type SettingsProps = {
  settings: SkipSettings;
  updateSkipSettings: (event: ChangeEvent<HTMLInputElement>) => void;
  toggleAllSkipSettings: (event: ChangeEvent<HTMLInputElement>) => void;
  isPremium: boolean;
};

const Context = React.createContext<SettingsProps>({
  settings: defaultSkipSettings,
  updateSkipSettings: () => {},
  toggleAllSkipSettings: () => {},
  isPremium: false
});

export const Settings: React.FC<SettingsProps> = props => {
  const { isPremium } = props;
  const { t } = useTranslation('common');
  return (
    <div className="border-b-2 px-4">
      <div className="mb-4 mt-4 flex items-center">
        <h4 className="font-medium" data-tip data-tooltip-id="skip_content">
          {t('Skip Content')}
        </h4>
        <ReactTooltip id="skip_content" className="!opacity-100 !-left-[80px] !top-[6px]" variant="light">
          <div className="flex max-w-libraryCard flex-col items-center justify-center">
            <div className="font-bold">{t('Choose what to skip')}</div>
            <div>{t('We automatically find')}</div>
          </div>
        </ReactTooltip>
        {!isPremium && (
          <Fragment>
            <Diamond
              data-tip
              data-tooltip-id="lock"
              className="ml-[6px] [&>g>#diamond-background]:fill-glass-0 dark:[&>g>#diamond-background]:fill-glass-700"
            />
            <ReactTooltip id="lock" className="!opacity-100" place="top" variant="dark">
              {t('Premium feature')}
            </ReactTooltip>
          </Fragment>
        )}
      </div>

      <Context.Provider value={props}>
        <SkipOptions />
      </Context.Provider>
    </div>
  );
};

const labelMap: Record<keyof SkipSettings, string> = {
  braces: 'Braces {}',
  citations: 'Citations',
  footers: 'Footers',
  footnotes: 'Footnotes',
  headers: 'Headers',
  roundBrackets: 'Parentheses ()',
  squareBrackets: 'Brackets []',
  urls: 'Urls'
};

// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const labelFor = (key: string) => labelMap[key];

export const SkipAllToggle: React.FC<{
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
}> = ({ isCollapsed, setIsCollapsed }) => {
  const { isPremium, toggleAllSkipSettings, settings } = useContext(Context);

  const disabled = !isPremium;

  const { matches: isSmallDesktopScreen } = useMediaQuery('(max-width: 1500px)');
  const { t } = useTranslation('common');
  const allSkips = useMemo<boolean>(() => Object.values(settings).every(value => value), [settings]);

  return (
    <div className={twMerge('flex items-start', isSmallDesktopScreen ? 'my-1' : 'my-2')}>
      <div className={twMerge('relative mr-8 inline-block w-8 select-none align-middle transition duration-200 ease-in sm:mr-2 sm:w-10')}>
        <input
          id="skip-all-toggle"
          type="checkbox"
          title="Skip all"
          className={twMerge(
            'toggle-checkbox absolute block h-4 w-4 appearance-none rounded-full border-2 border-gray-300 bg-white sm:h-5 sm:w-5', //
            'cursor-pointer disabled:cursor-not-allowed'
          )}
          checked={disabled ? false : allSkips}
          onChange={toggleAllSkipSettings}
          disabled={disabled}
          aria-describedby="skip-all-toggle-text"
        />
        <label
          htmlFor="skip-all-toggle"
          className={twMerge(
            'toggle-label block h-4 w-8 overflow-hidden rounded-full bg-gray-300 disabled:cursor-not-allowed sm:h-5 sm:w-10',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
        />
      </div>
      <div>
        <label
          htmlFor="skip-all-toggle"
          className={twMerge(
            'text-xs text-gray-600 sm:text-sm', //
            'flex',
            'items-center gap-1',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
        >
          <span>{t('Skip all')}</span>
        </label>
        <p className="flex text-xs" id="skip-all-toggle-text">
          {isCollapsed ? (
            <button className="flex items-center gap-1 text-xs text-electric-350" onClick={() => setIsCollapsed(false)}>
              <span>{t('Collapse')}</span>
              <ChevronUpIcon className="inline w-4" aria-hidden="true" />
            </button>
          ) : (
            <>
              <span className="text-glass-500">{t('Headers, Citations, and')}</span>
              <button className="mx-1 flex items-center bg-auto text-electric-350 outline-none" onClick={() => setIsCollapsed(true)}>
                <span>{t('more')}</span>
                <ChevronDownIcon className="inline w-4" aria-hidden="true" />
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

const SkipOptions: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const { matches: isSmallDesktopScreen } = useMediaQuery('(max-width: 1500px');

  const { isPremium, settings, updateSkipSettings } = useContext(Context);
  const disabled = !isPremium;

  return (
    <div className="mb-2">
      <SkipAllToggle isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      {isCollapsed && (
        <div className="mb-2 rounded bg-glass-300 px-4 py-3">
          {Object.entries(settings).map(([key, value]) => (
            <div
              key={key}
              className={twMerge(
                'relative flex items-center gap-2 align-middle',
                'transition duration-200 ease-in',
                'mb-4 ' + isSmallDesktopScreen ? 'my-1' : 'my-2',
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
            >
              <input
                type="checkbox"
                id={key}
                name={key}
                checked={disabled ? false : value}
                onChange={updateSkipSettings}
                disabled={disabled}
                className={twMerge(
                  'h-4 w-4 rounded border-gray-300 bg-gray-100 text-electric-400 focus:p-0 focus:outline-0 focus:ring-0', //
                  disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                )}
              />
              <label htmlFor={key} className={twMerge('flex gap-1 text-sm text-glass-600', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}>
                <span>{labelFor(key)}</span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
