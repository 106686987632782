import { auth } from 'lib/speechify';
import { get, pick } from 'lodash';

import type { Category as VoiceCategory, Tab as VoiceTab, Voice, VoiceList as VoiceListResponse } from '@speechifyinc/centralized-voice-list';
import { Voice as SDKVoice, VoiceGender, VoiceSpec, VoiceSpecOfAvailableVoice } from '@speechifyinc/multiplatform-sdk/api';

import { WebBoundaryMap } from './adaptors/boundarymap';

export enum GenderCode {
  Male = 'male',
  Female = 'female',
  NotSpecified = 'notSpecified'
}

export type VoiceDefinition = Voice & { avatarImage: string; premium: boolean };

const LOCAL_SYNTH_ENGINE = 'speechSynth';

const defaultVoiceListUrl = `${process.env.NEXT_PUBLIC_AUDIO_SERVER_URL}/v1/synthesis/client-voices`;
const ignoredVoiceUris = ['Google', 'com.apple.speech', 'com.apple.eloquence', 'Grandma', 'Grandpa', 'Bells', 'Trinoids'];

const getRemoteVoiceList = async (voiceListUrl: string) => {
  const idToken = await auth.currentUser?.getIdToken();

  return fetch(voiceListUrl, {
    headers: {
      ContentType: 'application/json',
      Authorization: `Bearer ${idToken}`,
      'X-Speechify-Client': 'WebApp',
      'X-Speechify-Client-Version': process.env.version || '0.0.0'
    }
  })
    .then(res => res.json() as Promise<VoiceListResponse>)
    .catch(() => fetch('/centralized-voice-list.json').then(res => res.json() as Promise<VoiceListResponse>));
};

const getDefaultVoiceList = async () => {
  const voiceListInStorage = localStorage.getItem('centralized-voice-list');
  if (voiceListInStorage) {
    const { voiceList, lastModifiedAt } = JSON.parse(voiceListInStorage) as { voiceList: VoiceListResponse; lastModifiedAt: number };
    if (Date.now() - lastModifiedAt < 60 * 60 * 1000 && voiceList) return voiceList;
  }

  const voiceList = await getRemoteVoiceList(defaultVoiceListUrl);
  localStorage.setItem('centralized-voice-list', JSON.stringify({ voiceList: voiceList, lastModifiedAt: Date.now() }));

  return voiceList;
};

const getFlagFromLang = (languageCode: string) => {
  const flag = languageCode.split('-')[1] || 'US';
  const isNumber = /^\d+$/.test(flag);

  return `${isNumber ? 'XX' : flag}.svg`;
};

const getAllVoicesFromList = (voiceList: VoiceListResponse) => {
  // extension's voice selector only has one tab
  const relevantTab = voiceList.tabs.find(tab => tab.displayName === 'All')!;
  return relevantTab.categories.flatMap(category => category.voices.map(mapVoiceToDefinition));
};

const findVoiceBy = (voiceInfo: Partial<Voice>, voices: Array<VoiceDefinition>) => {
  return findVoiceDefinitionBy(voiceInfo, voices);
};

const findSimilarVoice = (voice: { name: string; displayName: string; language: string }, voices: Array<VoiceSpecOfAvailableVoice>) => {
  const currentLanguageCode = voice.language.split('-')[0];
  const voiceList = ['Samantha', 'David', 'Alex', 'Daniel', 'Karen', 'Moira'];
  const voicesByLang = voices.filter(voice => voice.toVoiceMetadata().languageCode.startsWith(currentLanguageCode));
  const closestVoiceDefinition =
    voiceList.map(name => voicesByLang.find(voice => voice.displayName?.toLowerCase().includes(name?.toLowerCase()))).filter(Boolean)[0] ??
    voicesByLang.find(voice => voiceList.some(name => voice.displayName?.toLowerCase().includes(name?.toLowerCase()))) ??
    voicesByLang[0];

  return closestVoiceDefinition;
};

const findVoiceDefinitionBy = (voiceInfo: Partial<Voice>, voices: Array<VoiceDefinition>) => {
  if (Object.values(voiceInfo).length === 0) throw new Error('Cannot get voice with no params');
  const keys = Object.keys(voiceInfo) as (keyof Voice)[];
  return voices.find(voice => {
    return keys.every(key => {
      if (['localizedDisplayName', 'labels'].includes(key) || !voice[key]) return true;
      if (key === 'gender' && voiceInfo.engine === LOCAL_SYNTH_ENGINE && voice.gender === GenderCode.NotSpecified) {
        return true;
      }
      return voice[key] === voiceInfo[key];
    });
  });
};

const isVoiceEquivalent = (v1: Partial<Voice>, v2: Partial<Voice>) => {
  const keys = ['language', 'name', 'engine'] as const;

  return keys.every(key => {
    let v1Key = v1[key];
    let v2Key = v2[key];

    if (key === 'language') {
      // ESLint: Unexpected any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      v1Key ||= (v1 as any)['languageCode'];
      // ESLint: Unexpected any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      v2Key ||= (v2 as any)['languageCode'];
    }

    if (!v1Key || !v2Key) return true;

    if (key === 'engine') {
      v1Key = v1Key === 'local' ? LOCAL_SYNTH_ENGINE : v1Key;
      v2Key = v2Key === 'local' ? LOCAL_SYNTH_ENGINE : v2Key;
    }

    return v1Key!.toLowerCase() === v2Key!.toLowerCase();
  });
};

const mapVoiceToDefinition = (voice: Voice): VoiceDefinition => ({
  ...voice,
  avatarImage: voice.avatarImage ? voice.avatarImage : getFlagFromLang(voice.language),
  premium: voice.engine !== LOCAL_SYNTH_ENGINE
});

const mapVoiceSpecOfAvailableVoiceToVoiceDefinition = (voice: VoiceSpecOfAvailableVoice): VoiceDefinition => {
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = pick(voice as any, ['avatarUrl', 'displayName', 'name', 'engine', 'gender', 'languageCode', 'isPremium']);
  const { avatarUrl, displayName, name, engine, gender, languageCode, isPremium } = props;
  return { avatarImage: avatarUrl, displayName, localizedDisplayName: displayName, engine, gender, language: languageCode, name, premium: isPremium };
};

const getClientVoices = async () => {
  const localVoices = await new Promise<SpeechSynthesisVoice[]>(resolve => {
    const voices = speechSynthesis.getVoices();

    if (voices.length > 0) resolve(voices);

    speechSynthesis.addEventListener(
      'voiceschanged',
      () => {
        resolve(speechSynthesis.getVoices());
      },
      { once: true }
    );
  });

  const includedVoices = localVoices.filter(voice => !ignoredVoiceUris.find(key => voice.voiceURI.startsWith(key)));

  return includedVoices.map(
    voice =>
      ({
        name: voice.voiceURI,
        displayName: voice.name,
        localizedDisplayName: {},
        labels: [],
        engine: LOCAL_SYNTH_ENGINE,
        gender: GenderCode.NotSpecified,
        language: voice.lang
      }) as Voice
  );
};

const previews: Record<string, ((opts: { country: string; language: string; name: string }) => string) | undefined> = {
  // English
  // ESLint: 'country' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  en: ({ country, language, name }) => `Hi, my name is ${name}. I am an ${language} voice.`,

  // Arabic
  ar: ({ country, language, name }) => `Marhaba, ismy ${name}. souty bil-lougha ${language} al ${country}.`,

  // Danish -- Google Translate
  da: ({ name }) => `Hej, jeg hedder ${name}. Jeg er en dansk stemme.`,

  // Germany
  de: ({ language, name }) => `Hallo, mein Name ist ${name}. Ich bin eine ${language} Stimme.`,

  // Spanish
  es: ({ country, name }) => `Hola, Soy ${name}! Soy una voz española de ${country}.`,

  // France
  fr: ({ country, language, name }) => `Bonjour, je m'appelle ${name}. Je suis un voix ${language} provenant de ${country}.`,

  // Hindi
  hi: ({ name, language }) => `नमस्ते, मेरा नाम ${name} है। मैं एक ${language} की आवाज हूं।`,

  // Hindi
  te: () => `హాయ్ నా పేరు నరేంద్ర, నేను తెలుగు వాణిని`,

  // Italian -- Google Translate
  it: ({ name }) => `Hej, jeg hedder ${name}. Jeg er en italiensk stemme.`,

  // Japanese
  ja: undefined,

  // Korean
  ko: undefined,

  // Norwegian -- Google Translate
  nb: ({ name }) => `Hei, jeg heter ${name}. Jeg er en svensk stemme.`,

  // Dutch
  nl: ({ country, language, name }) => `Hi, mijn naam is ${name}. Ik ben een ${country} ${language} stem.`,

  // Polish -- Google Translate
  pl: ({ name }) => `Cześć, nazywam się ${name}. Jestem polskim głosem.`,

  // Portuguese -- Google Translate
  pt: ({ name }) => `Olá, meu nome é ${name}. Eu sou uma voz portuguesa.`,

  // Russian
  ru: ({ country, language, name }) => `Привет, меня зовут ${name}. Я ${country} ${language}.`,

  // Swedish -- Google Translate
  sv: ({ name }) => `Hej, jag heter ${name}. Jag är en svensk röst.`,

  // Turkish -- Google Translate
  tr: ({ name }) => `Merhaba, benim adım ${name}. Ben isveç sesim.`,

  // Chinese -- Simplified
  zh: ({ country, language, name }) => `您好，我是 ${name}。我是 ${country} ${language} 的声音。`,

  // Ukranian
  uk: ({ country, name }) => `Привіт, мене звати ${name}. Я голос з ${country}.`,

  bg: ({ country, name }) => `Здравейте, казвам се ${name}. Аз съм от ${country}.`
};

function removeBrackets(str: string) {
  const regex = /\(([^()]*|\([^()]*\))*\)/g;
  return str.replace(regex, '').trim();
}

/** Takes a voice object from the supported voices array */
export const getPreview = ({ language: localeCode, ...voice }: Voice) => {
  const [languageCode, countryCode = 'US'] = localeCode
    .split('-')
    .filter(Boolean)
    .map(str => str.toLowerCase());

  const countryNames = new Intl.DisplayNames([localeCode], { type: 'region' });
  const languageNames = new Intl.DisplayNames([localeCode], { type: 'language' });

  if (!previews[languageCode]) {
    console.warn(`Language code ${languageCode} does not have a preview. Using English as fallback`);
  }

  const previewGetter = previews[languageCode] ?? previews.en!;

  return previewGetter({
    name: removeBrackets(voice.displayName ?? voice.name),
    language: languageNames.of(languageCode) ?? languageCode,
    country: countryNames.of(countryCode.toUpperCase()) ?? countryCode
  });
};

const findVoiceFromSdk = (voice: SDKVoice, voices: Array<VoiceDefinition>): VoiceDefinition | undefined => {
  return voices.find(v => v.displayName === voice.metadata.displayName && isEngineEquivalent(v.engine, voice.metadata.engine));
};

const isEngineEquivalent = (engine: string, engineFromSdk: string) => engine === engineFromSdk || (engine === LOCAL_SYNTH_ENGINE && engineFromSdk === 'local');

const compareVoices = (voice: SDKVoice, otherVoice: Voice) => {
  return voice.metadata.displayName === otherVoice.displayName && isEngineEquivalent(otherVoice.engine, voice.metadata.engine);
};

const findVoiceInSDK = (voice: Voice, voiceSpec: SDKVoice[]): SDKVoice | undefined => voiceSpec.find(v => compareVoices(v, voice));

const speedToWPM = (speed: number) => Math.round(speed * 220);

const generateVoiceSpecs = async (voiceList: Array<VoiceDefinition>) => {
  const localVoices = await (await getClientVoices()).map(mapVoiceToDefinition);
  const localVoiceSpec = await Promise.all(
    localVoices
      .filter(voice => !voice.name.startsWith('Google'))
      .filter(voice => !voice.name.startsWith('com.apple') || !voice.name.includes('synthesis'))
      .map(voice => generateLocalVoiceSpec(voice))
  );

  const remoteVoiceSpec = await Promise.all(voiceList.map(voice => generateRemoteVoiceSpec(voice)));

  return [...localVoiceSpec, ...remoteVoiceSpec];
};

const generateLocalVoiceSpec = (voice: VoiceDefinition) => new VoiceSpec.Local(voice.displayName, voice.name, voice.avatarImage);
const generateRemoteVoiceSpec = (voice: VoiceDefinition) => {
  return new VoiceSpec.CVLVoiceSpec(
    voice.displayName,
    true,
    voice.engine,
    voice.language,
    voice.gender === 'male' ? VoiceGender.MALE : VoiceGender.FEMALE,
    voice.avatarImage,
    voice.labels ?? ['premium'],
    new WebBoundaryMap(voice.localizedDisplayName),
    voice.previewAudio,
    getPreview(voice),
    voice.name
  );
};

const generateVoiceSpec = (voice: VoiceDefinition) => (voice.engine === LOCAL_SYNTH_ENGINE ? generateLocalVoiceSpec(voice) : generateRemoteVoiceSpec(voice));

const isClonedVoice = (voice: VoiceDefinition | VoiceSpecOfAvailableVoice | undefined): boolean => (get(voice, 'name') || '').startsWith('PVL:');

export {
  findSimilarVoice,
  findVoiceBy,
  findVoiceDefinitionBy,
  findVoiceFromSdk,
  findVoiceInSDK,
  generateLocalVoiceSpec,
  generateVoiceSpec,
  generateVoiceSpecs,
  getAllVoicesFromList,
  getClientVoices,
  getDefaultVoiceList,
  getRemoteVoiceList,
  isClonedVoice,
  isEngineEquivalent,
  isVoiceEquivalent,
  mapVoiceSpecOfAvailableVoiceToVoiceDefinition,
  mapVoiceToDefinition,
  speedToWPM,
  Voice,
  VoiceCategory,
  VoiceListResponse,
  VoiceTab
};
