import { getFeatureVariant, isFeatureVariantReady, useFeatureVariant } from 'hooks/useFeatureFlags';
import { useMemo } from 'react';

import { FeatureNameEnum, SkipHeaderFooterUpsellTestVariant } from 'constants/featureDefinitions';

export async function isSkipHeaderFooterUpsellEnabled(isPremium: boolean): Promise<boolean> {
  const variant = await getFeatureVariant(FeatureNameEnum.SKIP_HEADER_FOOTER_UPSELL, {
    customVariantsMapper: [
      {
        variant: SkipHeaderFooterUpsellTestVariant.EXCLUDED,
        condition: async () => isPremium
      }
    ]
  });
  return variant === SkipHeaderFooterUpsellTestVariant.SHOW;
}

export const useSkipHeaderFooterUpsell = (isPremium: boolean) => {
  const featureStatus = useFeatureVariant(FeatureNameEnum.SKIP_HEADER_FOOTER_UPSELL, {
    customVariantsMapper: [
      {
        variant: SkipHeaderFooterUpsellTestVariant.EXCLUDED,
        condition: async () => isPremium
      }
    ]
  });

  const isUpsellEnabled = useMemo(() => {
    if (isFeatureVariantReady(featureStatus)) {
      return featureStatus.variant === SkipHeaderFooterUpsellTestVariant.SHOW;
    }
    return false;
  }, [featureStatus]);

  return {
    isUpsellEnabled
  };
};
