import { createNonPersistentStore } from 'lib/zustand';
import { User as CheckoutComponentUser } from '@speechifyinc/checkout-component';
import { firebaseAuth } from 'lib/firebase/firebase.client';
import { User } from 'firebase/auth';
import { logSegmentEvent } from 'utils/analytics';
import { UpgradeSource } from 'modules/subscription/utils/UpgradeSource';

type CheckoutState = {
  isModalOpen: boolean;
  userWithToken: CheckoutComponentUser | null;
};

const getJwtToken = (token: string) => {
  return fetch('/api/auth/create-jwt-token', { method: 'GET', cache: 'no-cache', headers: { Authorization: `Bearer ${token}` } }).then(res => res.json());
};

const getCheckoutUser = async (user: User | null) => {
  if (user) {
    const idToken = await user.getIdToken();
    const jwt = await getJwtToken(idToken);
    return {
      uid: user.uid,
      email: user.email,
      idToken,
      jwt
    } as CheckoutComponentUser;
  }
};

const createCheckoutStore = () => {
  const store = createNonPersistentStore<CheckoutState>(() => {
    return {
      isModalOpen: false,
      userWithToken: null
    };
  });

  return store;
};

export const useCheckoutStore = createCheckoutStore();

const closeCheckoutModal = () => {
  useCheckoutStore.setState({
    isModalOpen: false
  });
};

const openCheckoutModal = (source?: UpgradeSource) => {
  useCheckoutStore.setState({
    isModalOpen: true
  });
  logSegmentEvent('checkout_modal_opened', { source });
  const state = useCheckoutStore.getState();
  if (!state.userWithToken) {
    getCheckoutUser(firebaseAuth.currentUser).then(user => {
      useCheckoutStore.setState({
        userWithToken: user
      });
    });
  }
};

export const checkoutActions = {
  closeCheckoutModal,
  openCheckoutModal
};
