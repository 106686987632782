import type { LibraryItem as SDKLibraryItem } from '@speechifyinc/multiplatform-sdk';

import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';

export class SDKLibraryFacade extends SDKFacade {
  private static _singleton: SDKLibraryFacade;
  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKLibraryFacade._singleton = this;
  }

  static override get singleton(): SDKLibraryFacade {
    return SDKLibraryFacade._singleton;
  }

  public getItem = this.sdk.promisify(this.sdk.client.libraryService.getItem.bind(this.sdk.client.libraryService));

  public getItemAndWaitUntilListenable = async (itemId: string): Promise<SDKLibraryItem> => {
    const SDKContent = this.sdk.sdkModule.LibraryItem.Content;

    const item = await this.getItem(itemId);
    if (item instanceof SDKContent) {
      if (!item.isInListenableState) {
        await new Promise<void>(resolve => {
          const interval = setInterval(async () => {
            const updatedItem = (await this.getItem(itemId)) as SDKLibraryItem.Content;
            if (updatedItem.isInListenableState) {
              clearInterval(interval);
              resolve();
            }
          }, 1000);
        });
      }
      return item;
    }
    return item;
  };
}
