import { IMainPageState, InstantListening } from 'interfaces';

import { createSlice } from '@reduxjs/toolkit';

const name = 'mainPage';

const initialState: IMainPageState = {
  activeDropTargetId: undefined,
  activeDragTargetId: undefined,
  instantListening: null,
  isDragging: false,
  mobileAction: '',
  newFolderParentId: '',
  newlyImportedItemId: null
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setInstantListening: (state, action: { payload: InstantListening | null }) => {
      state.instantListening = action.payload;
    },

    updateInstantListening: (state, action) => {
      if (state.instantListening) {
        state.instantListening = {
          ...state.instantListening,
          ...action.payload
        };
      }
    },

    setNewlyImportedItemId: (state, action: { payload: string | null }) => {
      state.newlyImportedItemId = action.payload;
    },

    setMobileAction: (state, action: { payload: string }) => {
      state.mobileAction = action.payload;
    },

    setNewFolderParentId: (state, action: { payload: string | undefined }) => {
      state.newFolderParentId = action.payload;
    },

    setIsDragging: (state, action: { payload: boolean }) => {
      state.isDragging = action.payload;
    },

    setActiveDropTargetId: (state, action: { payload: string | undefined }) => {
      state.activeDropTargetId = action.payload;
    },

    setActiveDragTargetId: (state, action: { payload: string | undefined }) => {
      state.activeDragTargetId = action.payload;
    }
  }
});

const actions = { ...generatedActions };
const selectors = {};

export { actions, selectors };

export default reducer;
