import { PersonalVoiceActionType } from 'interfaces';

import { createSlice } from '@reduxjs/toolkit';
import type { VoiceSpecOfAvailableVoice } from '@speechifyinc/multiplatform-sdk';

import { deletePersonalVoice, refresh } from './actions';

const name = 'personalVoices';

const initialState: {
  confirmItem?: VoiceSpecOfAvailableVoice;
  confirmItemAction?: PersonalVoiceActionType;
  deletedIds: Set<string>;
  personalVoices: VoiceSpecOfAvailableVoice[];
  selectedPersonalVoice?: { displayName: string; engine: string; gender: string; languageCode: string; name: string; isPersonalVoice: boolean };
  isLoading: boolean;
} = {
  deletedIds: new Set(),
  personalVoices: [],
  isLoading: true,
  selectedPersonalVoice: undefined
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    clear: state => {
      state.personalVoices = [];
      state.isLoading = false;
    },
    clearSelection: state => {
      state.selectedPersonalVoice = undefined;
    },
    select: (state, action) => {
      state.selectedPersonalVoice = action.payload;
    },
    set: (state, action) => {
      state.personalVoices = action.payload;
      state.isLoading = false;
    },
    setConfirmItem: (state, action: { payload: { item: VoiceSpecOfAvailableVoice; action: PersonalVoiceActionType } | undefined }) => {
      if (action && action.payload?.item && action.payload?.action) {
        state.confirmItem = action.payload.item;
        state.confirmItemAction = action.payload.action;
      } else {
        state.confirmItem = undefined;
        state.confirmItemAction = undefined;
      }
    },
    setDeletedId: (state, action) => {
      state.deletedIds.add(action.payload);
    }
  }
});

const actions = { ...generatedActions, deletePersonalVoice, refresh };
const selectors = {};

export { actions, selectors };

export default reducer;
