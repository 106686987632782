import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';

import { createAsyncThunk } from '@reduxjs/toolkit';

export enum UsageActionType {
  fetchUserUsagedata = 'usage/fetchUserUsagedata'
}

export const fetchUserUsageData = createAsyncThunk(UsageActionType.fetchUserUsagedata, async (_, { rejectWithValue }) => {
  try {
    return await speechify.fetchUserUsageData();
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});
